<template>
  <v-container fluid>
    <!-- <v-overlay opacity=".8" absolute v-if="loadingData">
			<v-progress-circular
				indeterminate
				size="100"
				width="10"
				color="primary"
			></v-progress-circular>
		</v-overlay>-->
    <v-form ref="formularioContrato">
      <div
        no-gutters
        align="center"
        class="mb-3 d-flex flex-wrap"
        style="gap: 10px"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              class="mr-1"
              color="primary"
              large
              :loading="cargandoContrato"
              @click="
                esBorrador = true;
                cargarContrato(false);
              "
            >
              <v-icon color="white" left>mdi-content-save</v-icon>GUARDAR
              BORRADOR
            </v-btn>
          </template>
          <span>Guardar el contrato temporalmente como borrador</span>
        </v-tooltip>
        <v-tooltip v-if="$route.query.id" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              class="mx-1"
              color="primary"
              large
              :loading="cargandoContrato"
              @click="
                esBorrador = false;
                cargarContrato(false);
              "
              :disabled="documentos.length == 0 && !contrato.residencial"
            >
              <v-icon color="white" left>mdi-upload</v-icon>ENVIAR A REVISIÓN
            </v-btn>
          </template>
          <span>Validar los datos y enviar a revisión</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              large
              text
              class="mx-1"
              :loading="cargandoContrato"
              @click="cargarContrato(true)"
              :color="errors == null ? '' : errors ? 'error' : 'success'"
            >
              <v-icon left v-if="errors === true"
                >mdi-alert-circle-outline</v-icon
              >
              <v-icon left v-else-if="errors === false">mdi-check</v-icon
              >Consultar campos
            </v-btn>
          </template>
          <span v-if="!errors">Consultar campos faltantes o erroneos</span>
          <span v-else>Hay campos erróneos, revisa todos los campos</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip v-if="$route.query.id" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              class="mx-1"
              color="error"
              :loading="cargandoContrato"
              @click="
                esBorrador = true;
                eliminarBorradores('Eliminado', $route.query.id);
              "
            >
              <v-icon color="white" left>mdi-delete</v-icon>ELIMINAR BORRADOR
            </v-btn>
          </template>
          <span>Eliminar borrador</span>
        </v-tooltip>

        <!-- <v-checkbox
					v-model="contrato.certificadoElectronico"
					class="ml-1"
					label="Proceso certificado electrónico"
				></v-checkbox>-->
      </div>
      <v-row>
        <v-col cols="auto">
          <v-checkbox
            hide-details
            v-model="contrato.enviarSMS"
            class="ml-1 mt-0"
            label="Enviar firma por SMS"
            :disabled="!contrato.datosTitular.telefonoMovil"
            @change="(v) => (v == true ? (contrato.enviarEmail = false) : null)"
          ></v-checkbox>
        </v-col>
        <v-col cols="auto">
          <v-checkbox
            v-model="contrato.enviarEmail"
            class="ml-1 mt-0"
            label="Enviar firma por Email"
            :disabled="!contrato.datosTitular.email"
            @change="(v) => (v == true ? (contrato.enviarSMS = false) : null)"
          ></v-checkbox>
        </v-col>
        <v-col cols="auto">
          <v-checkbox
            v-model="contrato.residencial"
            class="ml-1 mt-0"
            label="Residencial"
          ></v-checkbox>
        </v-col>
      </v-row>

      <div class="d-flex flex-wrap mb-3" style="gap: 10px">
        <!-- <v-alert
					class="mb-0"
					dense
					type="info"
					text
					v-if="$store.getters.getEntorno != 'Luz'"
				>El producto flexible solo está disponible en electricidad</v-alert>-->
        <v-alert
          class="mb-0"
          dense
          type="info"
          text
          v-if="!(contrato.enviarSMS || contrato.enviarEmail)"
          >Si no se selecciona un tipo de firma, la firma será manual</v-alert
        >
        <v-alert class="mb-0" dense type="info" text v-if="!$route.query.id">
          La documentación no está disponible hasta que el borrador esté creado
        </v-alert>
        <v-alert
          class="mb-0"
          dense
          type="warning"
          text
          v-else-if="documentos.length == 0 && !contrato.residencial"
          >Es obligatorio adjuntar la documentación</v-alert
        >
        <v-alert
          v-for="(incidencia, index) in incidencias"
          :key="index"
          dense
          type="error"
          text
          class="mb-0"
        >
          {{ incidencia.Mensaje }}
          <br v-if="incidencia.info && incidencia.info.length > 0" />
          <span v-if="incidencia.info && incidencia.info.length > 0">{{
            incidencia.info.join(", ")
          }}</span>
        </v-alert>
      </div>

      <v-tabs v-model="tab" show-arrows>
        <v-tab tab="1">General</v-tab>
        <v-tab tab="2">Contrato</v-tab>
        <v-tab tab="3">Otros</v-tab>
        <v-tab :disabled="atrPotencia == false" tab="4"
          >Producto flexible</v-tab
        >
        <v-tab :disabled="!$route.query.id" tab="5">Documentacion</v-tab>
      </v-tabs>

      <v-tabs-items touchless v-model="tab">
        <v-tab-item eager>
          <v-row>
            <v-col cols="12" class="col-md-6">
              <v-card outlined>
                <v-sheet
                  :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'"
                  class="d-flex pa-4"
                >
                  <v-card-subtitle class="pa-0">
                    <v-icon small>mdi-pencil</v-icon>&nbsp;Punto Suministro
                  </v-card-subtitle>
                  <v-spacer />
                  <v-btn text small @click="duplicarEnContrato">
                    <v-card-subtitle>
                      <v-icon small>mdi-content-copy</v-icon>&nbsp;Duplicar
                      datos en contrato
                    </v-card-subtitle>
                  </v-btn>
                </v-sheet>

                <v-divider />

                <aux-input title="CUPS *">
                  <v-text-field
                    dense
                    hide-details="auto"
                    filled
                    placeholder="INTRODUZCA CUPS"
                    v-model="contrato.puntoSuministro.cups"
                    :rules="[rules.cups, rules.req]"
                    @change="buscarCups"
                  >
                    <template v-slot:append-outer>
                      <v-btn class="mt-n2" color="primary" @click="buscarCups">
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </aux-input>
                <v-divider />
                <aux-input title="Código Distribuidora *">
                  <v-text-field
                    dense
                    hide-details="auto"
                    filled
                    placeholder="INTRODUZCA CODIGO DISTRIBUIDORA"
                    v-model="contrato.puntoSuministro.codDistribuidora"
                    :rules="[rules.req]"
                  />
                </aux-input>
                <v-divider />

                <aux-input title="Codigo Comercializadora">
                  <v-text-field
                    dense
                    hide-details="auto"
                    disabled
                    filled
                    v-model="contrato.puntoSuministro.codComercializadora"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Ciudad *">
                  <v-autocomplete
                    dense
                    hide-details="auto"
                    hide-no-data
                    filled
                    placeholder="BUSCAR CIUDAD POR NOMBRE"
                    v-model="contrato.puntoSuministro.ciudad"
                    item-text="texto"
                    clearable
                    :return-object="true"
                    :loading="isLoading"
                    :items="ciudadesPosibles.puntoSuministro"
                    :search-input.sync="busqueda.ciudad.puntoSuministro"
                    :hint="contrato.puntoSuministro.sugerenciaCiudad"
                    persistent-hint
                    cache-items
                    :rules="[rules.req]"
                    @change="ciudadCambio()"
                  ></v-autocomplete>
                </aux-input>
                <v-divider />
                <aux-input title="Provincia *">
                  <v-text-field
                    dense
                    hide-details="auto"
                    filled
                    placeholder="INTRODUZCA PROVINCIA"
                    :rules="[rules.req]"
                    v-model="contrato.puntoSuministro.provincia"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Calle *">
                  <v-combobox
                    v-model="contrato.puntoSuministro.calle"
                    dense
                    hide-no-data
                    hide-details="auto"
                    filled
                    placeholder="Nombre Calle"
                    item-text="texto"
                    :disabled="!contrato.puntoSuministro.ciudad"
                    clearable
                    :return-object="true"
                    :loading="isLoadingCalle"
                    :items="callesPosibles.puntoSuministro"
                    :rules="[rules.req]"
                    @change="calleCambio()"
                    :hint="contrato.puntoSuministro.sugerenciaCalle"
                    persistent-hint
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Número *">
                  <v-text-field
                    dense
                    hide-details="auto"
                    filled
                    placeholder="INTRODUZCA NUMERO"
                    :rules="[rules.req]"
                    v-model="contrato.puntoSuministro.numero"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Aclarador *">
                  <v-text-field
                    dense
                    hide-details="auto"
                    filled
                    placeholder="INTRODUZCA ACLARADOR"
                    :rules="[rules.req]"
                    v-model="contrato.puntoSuministro.aclarador"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Código Postal *">
                  <v-text-field
                    dense
                    hide-details="auto"
                    filled
                    placeholder="INTRODUZCA CODIGO POSTAL"
                    :rules="[rules.req]"
                    v-model="contrato.puntoSuministro.codPostal"
                  />
                </aux-input>
                <v-divider />
              </v-card>
            </v-col>

            <v-col cols="12" class="col-md-6">
              <v-card outlined>
                <v-sheet
                  :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'"
                  class="d-flex pa-4"
                >
                  <v-card-subtitle class="pa-0">
                    <v-icon small>mdi-pencil</v-icon>&nbsp;Detalles de
                    contratación
                  </v-card-subtitle>
                </v-sheet>

                <v-divider />
                <aux-input title="Tarifa *">
                  <v-select
                    filled
                    placeholder="Seleccione una tarifa de Acceso*"
                    dense
                    hide-details="auto"
                    v-model="contrato.detallesContratacion.tarifa"
                    clearable
                    :items="selects.contratacion.tarifas"
                    item-text="TextoTarifa"
                    :rules="[rules.req]"
                    return-object
                    @change="getProductoContratacion"
                  />
                </aux-input>

                <v-divider />
                <aux-input title="Producto *">
                  <v-select
                    filled
                    :placeholder="
                      !esProductoFlexible
                        ? 'Seleccione un producto*'
                        : 'Producto flexible'
                    "
                    dense
                    hide-details="auto"
                    v-model="contrato.detallesContratacion.producto"
                    :items="selects.contratacion.producto"
                    item-text="TextoTarifaGrupo"
                    clearable
                    :rules="[esProductoFlexible || rules.req]"
                    return-object
                    :disabled="
                      contrato.detallesContratacion.tarifa == null ||
                        contrato.detallesContratacion.tarifa.length == 0
                    "
                    @change="
                      (v) =>
                        !!v
                          ? (contrato.detallesContratacion.perfilFacturacion = selects.contratacion.perfilFacturacion.find(
                              (per) =>
                                per.TextoPerfilFacturacion ==
                                v.TextoPerfilFacturacion
                            ))
                          : (contrato.detallesContratacion.perfilFacturacion = null)
                    "
                  />
                </aux-input>

                <v-divider />

                <template
                  v-if="
                    contrato.detallesContratacion.producto &&
                      contrato.detallesContratacion.producto.TextoTarifaGrupo.includes(
                        'FLEXIBLE'
                      )
                  "
                >
                  <v-expansion-panels flat :value="detallesFlexible.show">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <h5>Detalles Producto Flexible</h5>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12" sm="4">
                            <h5>Fee término de energía</h5>
                          </v-col>
                          <v-col>
                            <v-text-field
                              disabled
                              dense
                              hide-details="auto"
                              filled
                              :value="datosFlexible.ImporteEnergia"
                              suffix="EUR/MWh"
                            />
                          </v-col>
                        </v-row>
                        <v-row class="pa-2">
                          <v-col cols="12" class="col-sm-6 col-md-4">
                            <v-text-field
                              disabled
                              dense
                              hide-details="auto"
                              filled
                              :value="datosFlexible.feep1"
                              label="FEE P1"
                              suffix="€"
                            />
                          </v-col>
                          <v-col cols="12" class="col-sm-6 col-md-4">
                            <v-text-field
                              disabled
                              dense
                              hide-details="auto"
                              filled
                              :value="datosFlexible.feep2"
                              label="FEE P2"
                              suffix="€"
                            />
                          </v-col>
                          <v-col cols="12" class="col-sm-6 col-md-4">
                            <v-text-field
                              disabled
                              dense
                              hide-details="auto"
                              filled
                              :value="datosFlexible.feep3"
                              label="FEE P3"
                              suffix="€"
                            />
                          </v-col>
                          <v-col cols="12" class="col-sm-6 col-md-4">
                            <v-text-field
                              disabled
                              dense
                              hide-details="auto"
                              filled
                              :value="datosFlexible.feep4"
                              label="FEE P4"
                              suffix="€"
                            />
                          </v-col>
                          <v-col cols="12" class="col-sm-6 col-md-4">
                            <v-text-field
                              disabled
                              dense
                              hide-details="auto"
                              filled
                              :value="datosFlexible.feep5"
                              label="FEE P5"
                              suffix="€"
                            />
                          </v-col>
                          <v-col cols="12" class="col-sm-6 col-md-4">
                            <v-text-field
                              disabled
                              dense
                              hide-details="auto"
                              filled
                              :value="datosFlexible.feep6"
                              label="FEE P6"
                              suffix="€"
                            />
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <v-divider />
                </template>

                <template v-if="$store.getters.getEntorno == 'Luz'">
                  <v-divider />
                  <v-row class="ma-1">
                    <v-col cols="12" class="col-md-6" v-for="i in 6" :key="i">
                      <aux-input :title="`Potencia P${i}`">
                        <v-text-field
                          filled
                          dense
                          hide-details="auto"
                          :placeholder="`Introduzca Potencia P${i}`"
                          v-model="contrato.detallesContratacion[`p${i}`]"
                          :rules="[rules.isNumber]"
                        />
                      </aux-input>
                    </v-col>
                  </v-row>
                </template>

                <template v-else-if="$store.getters.getEntorno == 'Gas'">
                  <v-divider />

                  <aux-input title="Caudal">
                    <v-text-field
                      dense
                      hide-details="auto"
                      filled
                      placeholder="Introduzca Caudal"
                    />
                  </aux-input>
                </template>
                <v-divider />

                <aux-input title="Consumo Anual SIPS KWh">
                  <v-text-field
                    dense
                    hide-details="auto"
                    filled
                    disabled
                    placeholder="Introduzca consumo del SIPS"
                    v-model="contrato.detallesContratacion.consumoAnualSips"
                  />
                </aux-input>
              </v-card>
            </v-col>

            <v-col cols="12" class="col-md-6">
              <v-card outlined>
                <v-sheet
                  :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'"
                  class="d-flex pa-4"
                >
                  <v-card-subtitle class="pa-0">
                    <v-icon small>mdi-pencil</v-icon>&nbsp;Servicios de valor
                    añadido
                  </v-card-subtitle>
                </v-sheet>

                <aux-input title="Grupo Producto">
                  <v-select
                    filled
                    placeholder="Seleccione un Grupo*"
                    dense
                    hide-details="auto"
                    v-model="contrato.serviciosValor.grupoProducto"
                    :items="selects.serviciosValor.grupoProducto"
                    item-text="TextoProductoGrupo"
                    clearable
                    @change="getProductoServicios"
                    return-object
                  />
                </aux-input>
                <aux-input title="Producto">
                  <v-select
                    filled
                    placeholder="Seleccione un Producto"
                    dense
                    hide-details="auto"
                    v-model="contrato.serviciosValor.producto"
                    :items="selects.serviciosValor.producto"
                    item-text="TextoProducto"
                    clearable
                  ></v-select>
                </aux-input>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <!-- TAB CONTRATO -->

        <v-tab-item eager>
          <v-row>
            <v-col cols="12" class="col-md-6">
              <v-card outlined>
                <v-sheet
                  :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'"
                  class="d-flex pa-4"
                >
                  <v-card-subtitle class="pa-0">
                    <v-icon small>mdi-pencil</v-icon>&nbsp;Datos titular
                  </v-card-subtitle>
                  <v-spacer />
                  <v-btn text small @click="duplicarEnEnvioYOtros()">
                    <v-card-subtitle>
                      <v-icon small>mdi-content-copy</v-icon>&nbsp;Duplicar
                      datos en envío y otros
                    </v-card-subtitle>
                  </v-btn>
                </v-sheet>

                <v-divider />
                <v-row class="ma-1">
                  <v-col cols="12" sm="4">
                    <h5>Identificador*</h5>
                    <small>CIF, NIF, NIE</small>
                  </v-col>
                  <v-col>
                    <v-text-field
                      dense
                      hide-details="auto"
                      filled
                      placeholder="INTRODUZCA IDENTIFICADOR"
                      :rules="[rules.req, rules.ValidateSpanishID]"
                      v-model="contrato.datosTitular.identificador"
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          class="mt-n2"
                          color="primary"
                          @click="buscarCliente(false)"
                        >
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-divider />

                <aux-input title="Nombre *">
                  <v-text-field
                    dense
                    hide-details="auto"
                    filled
                    placeholder="INTRODUZCA NOMBRE"
                    :rules="[!esEmpresa ? rules.req : true]"
                    v-model="contrato.datosTitular.nombre"
                    :disabled="esEmpresa"
                  />
                </aux-input>
                <v-divider />

                <aux-input title="Apellido 1*">
                  <v-text-field
                    dense
                    hide-details="auto"
                    filled
                    placeholder="Introduzca apellido 1"
                    :rules="[!esEmpresa ? rules.req : true]"
                    v-model="contrato.datosTitular.apellido1"
                    :disabled="esEmpresa"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Apellido 2">
                  <v-text-field
                    dense
                    hide-details="auto"
                    filled
                    placeholder="Introduzca apellido 2"
                    v-model="contrato.datosTitular.apellido2"
                    :disabled="esEmpresa"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Razón Social">
                  <v-text-field
                    dense
                    hide-details="auto"
                    filled
                    placeholder="INTRODUZCA RAZON SOCIAL"
                    :rules="[esEmpresa ? rules.req : true]"
                    v-model="contrato.datosTitular.razonSocial"
                    :disabled="!esEmpresa"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Ciudad *">
                  <v-autocomplete
                    dense
                    hide-details="auto"
                    hide-no-data
                    filled
                    placeholder="BUSCAR CIUDAD POR NOMBRE"
                    v-model="contrato.datosTitular.ciudad"
                    item-text="texto"
                    clearable
                    cache-items
                    :return-object="true"
                    :loading="isLoading"
                    :items="ciudadesPosibles.datosTitular"
                    :rules="[rules.req]"
                    :search-input.sync="busqueda.ciudad.datosTitular"
                    @change="ciudadCambio('datosTitular')"
                    :hint="contrato.datosTitular.sugerenciaCiudad"
                    persistent-hint
                  ></v-autocomplete>
                </aux-input>
                <v-divider />
                <aux-input title="Provincia *">
                  <v-text-field
                    dense
                    hide-details="auto"
                    filled
                    :rules="[rules.req]"
                    placeholder="INTRODUZCA PROVINCIA"
                    v-model="contrato.datosTitular.provincia"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Calle *">
                  <v-combobox
                    v-model="contrato.datosTitular.calle"
                    dense
                    hide-no-data
                    hide-details="auto"
                    filled
                    placeholder="Nombre Calle"
                    item-text="texto"
                    :disabled="!contrato.datosTitular.ciudad"
                    :rules="[rules.req]"
                    clearable
                    :return-object="true"
                    :loading="isLoadingCalle"
                    :items="callesPosibles.datosTitular"
                    @change="calleCambio('datosTitular')"
                    :hint="contrato.datosTitular.sugerenciaCalle"
                    persistent-hint
                  ></v-combobox>
                </aux-input>
                <v-divider />
                <aux-input title="Número *">
                  <v-text-field
                    dense
                    hide-details="auto"
                    :rules="[rules.req]"
                    filled
                    placeholder="INTRODUZCA NÚMERO"
                    v-model="contrato.datosTitular.numero"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Aclarador">
                  <v-text-field
                    dense
                    hide-details="auto"
                    filled
                    placeholder="INTRODUZCA ACLARADOR"
                    v-model="contrato.datosTitular.aclarador"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Codigo Postal *">
                  <v-text-field
                    dense
                    hide-details="auto"
                    :rules="[rules.req]"
                    filled
                    placeholder="INTRODUZCA CODIGO POSTAL"
                    v-model="contrato.datosTitular.codPostal"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Telefono fijo *">
                  <v-text-field
                    dense
                    hide-details="auto"
                    :rules="[
                      contrato.datosTitular.telefonoMovil == null ||
                      contrato.datosTitular.telefonoMovil == ''
                        ? rules.req
                        : true,
                      rules.isNumber,
                    ]"
                    filled
                    placeholder="INTRODUZCA TELEFONO"
                    v-model="contrato.datosTitular.telefonoFijo"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Telefono móvil *">
                  <v-text-field
                    dense
                    hide-details="auto"
                    :rules="[
                      contrato.datosTitular.telefonoFijo == null ||
                      contrato.datosTitular.telefonoFijo == ''
                        ? rules.req
                        : true,
                      rules.isNumber,
                    ]"
                    filled
                    placeholder="INTRODUZCA MOVIL"
                    v-model="contrato.datosTitular.telefonoMovil"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Email">
                  <v-text-field
                    dense
                    hide-details="auto"
                    filled
                    placeholder="INTRODUZCA EMAIL"
                    v-model="contrato.datosTitular.email"
                  />
                </aux-input>
                <v-divider />
              </v-card>
            </v-col>
            <v-col cols="12" class="col-md-6">
              <v-row no-gutters>
                <v-card outlined width="100%">
                  <v-sheet
                    :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'"
                    class="d-flex pa-4"
                  >
                    <v-card-subtitle class="pa-0">
                      <v-icon small>mdi-pencil</v-icon>&nbsp;Datos Envío
                    </v-card-subtitle>
                    <v-spacer />
                  </v-sheet>

                  <v-divider />
                  <aux-input title="Nombre *">
                    <v-text-field
                      dense
                      hide-details="auto"
                      filled
                      :rules="[rules.req]"
                      placeholder="INTRODUZCA NOMBRE"
                      v-model="contrato.datosEnvio.nombre"
                    />
                  </aux-input>
                  <v-divider />

                  <aux-input title="Ciudad *">
                    <v-autocomplete
                      dense
                      hide-details="auto"
                      hide-no-data
                      :rules="[rules.req]"
                      filled
                      placeholder="BUSCAR CIUDAD POR NOMBRE"
                      v-model="contrato.datosEnvio.ciudad"
                      item-text="texto"
                      clearable
                      cache-items
                      :return-object="true"
                      :loading="isLoading"
                      :items="ciudadesPosibles.datosEnvio"
                      :search-input.sync="busqueda.ciudad.datosEnvio"
                      @change="ciudadCambio('datosEnvio')"
                    ></v-autocomplete>
                  </aux-input>
                  <v-divider />

                  <aux-input title="Provincia *">
                    <v-text-field
                      dense
                      hide-details="auto"
                      disabled
                      filled
                      :rules="[rules.req]"
                      placeholder="INTRODUZCA LA PROVINCIA"
                      v-model="contrato.datosEnvio.provincia"
                    />
                  </aux-input>
                  <v-divider />
                  <aux-input title="Calle *">
                    <v-combobox
                      v-model="contrato.datosEnvio.calle"
                      dense
                      hide-no-data
                      hide-details="auto"
                      filled
                      placeholder="Nombre Calle"
                      :rules="[rules.req]"
                      item-text="texto"
                      :disabled="!contrato.puntoSuministro.ciudad"
                      clearable
                      :return-object="true"
                      :loading="isLoadingCalle"
                      :items="callesPosibles.datosEnvio"
                      @change="calleCambio('datosEnvio')"
                    ></v-combobox>
                  </aux-input>
                  <v-divider />
                  <aux-input title="Número *">
                    <v-text-field
                      dense
                      hide-details="auto"
                      filled
                      placeholder="INTRODUZCA NÚMERO"
                      :rules="[rules.req]"
                      v-model="contrato.datosEnvio.numero"
                    />
                  </aux-input>
                  <v-divider />
                  <aux-input title="Aclarador">
                    <v-text-field
                      dense
                      hide-details="auto"
                      filled
                      placeholder="INTRODUZCA ACLARADOR"
                      v-model="contrato.datosEnvio.aclarador"
                    />
                  </aux-input>
                  <v-divider />
                  <aux-input title="Codigo Postal *">
                    <v-text-field
                      dense
                      hide-details="auto"
                      filled
                      :rules="[rules.req]"
                      placeholder="INTRODUZCA CODIGO POSTAL"
                      v-model="contrato.datosEnvio.codPostal"
                    />
                  </aux-input>
                </v-card>
                <v-card outlined width="100%" class="mt-2">
                  <v-sheet
                    :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'"
                    class="d-flex pa-4"
                  >
                    <v-card-subtitle class="pa-0">
                      <v-icon small>mdi-note</v-icon>&nbsp;Observaciones
                    </v-card-subtitle>
                  </v-sheet>
                  <v-textarea
                    filled
                    auto-grow
                    no-resize
                    style="min-height: 200px"
                    v-model="contrato.observaciones"
                  ></v-textarea>
                </v-card>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>

        <!-- TAB OTROS -->

        <v-tab-item eager>
          <v-row>
            <v-col cols="12" class="col-md-6">
              <v-card outlined>
                <v-sheet
                  :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'"
                  class="d-flex pa-4"
                >
                  <v-card-subtitle class="pa-0">
                    <v-icon small>mdi-pencil</v-icon>&nbsp;Datos bancarios
                  </v-card-subtitle>
                </v-sheet>
                <v-divider />
                <aux-input title="Nombre Pagador *">
                  <v-text-field
                    dense
                    hide-details="auto"
                    filled
                    :rules="[rules.req]"
                    placeholder="Introduzca nombre"
                    v-model="contrato.datosBancarios.nombrePagador"
                  />
                </aux-input>
                <v-divider />
                <v-row class="ma-1">
                  <v-col cols="12" sm="4">
                    <h5>Identificador*</h5>
                    <small>CIF, NIF NIE</small>
                  </v-col>
                  <v-col>
                    <v-text-field
                      dense
                      hide-details="auto"
                      :rules="[rules.req]"
                      filled
                      placeholder="Introduzca identificador"
                      v-model="contrato.datosBancarios.identificador"
                    />
                  </v-col>
                </v-row>
                <v-divider />
                <aux-input title="Tipo Cobro * ">
                  <v-select
                    filled
                    dense
                    hide-details="auto"
                    label="Seleccione un Tipo de Cobro*"
                    v-model="contrato.datosBancarios.tipoCobro"
                    clearable
                    return-object
                    :rules="[rules.req]"
                    :items="selects.bancarios.tipoCobro"
                    item-text="TextoTipoCobro"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="IBAN *">
                  <v-text-field
                    dense
                    hide-details="auto"
                    filled
                    :rules="[
                      (v) =>
                        contrato.datosBancarios.tipoCobro &&
                        contrato.datosBancarios.tipoCobro.TextoTipoCobro ==
                          'NO DOMICILIADO'
                          ? true
                          : rules.req(v),
                      rules.iban,
                    ]"
                    placeholder="Introduzca IBAN"
                    v-model="contrato.datosBancarios.iban"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="BIC">
                  <v-text-field
                    dense
                    hide-details="auto"
                    filled
                    placeholder="Introduzca BIC"
                    v-model="contrato.datosBancarios.bic"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="CNAE">
                  <v-autocomplete
                    dense
                    hide-details="auto"
                    filled
                    label="Seleccione CNAE"
                    v-model="contrato.datosBancarios.cnae"
                    :items="selects.bancarios.cnae"
                    item-text="texto"
                    clearable
                    return-object
                    cache-items
                  ></v-autocomplete>
                </aux-input>
                <v-divider />
              </v-card>
              <v-card outlined v-if="esEmpresa" class="mt-1">
                <v-sheet
                  :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'"
                  class="d-flex pa-4"
                >
                  <v-card-subtitle class="pa-0">
                    <v-icon small>mdi-pencil</v-icon>&nbsp;Datos representante
                  </v-card-subtitle>
                </v-sheet>
                <v-divider />
                <aux-input title="Nombre del representante">
                  <v-text-field
                    type="text"
                    dense
                    hide-details="auto"
                    filled
                    v-model="contrato.datosRepresentante.nombreRepresentante"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Email representante">
                  <v-text-field
                    type="text"
                    dense
                    hide-details="auto"
                    filled
                    v-model="contrato.datosRepresentante.emailRepresentante"
                    :rules="[rules.email]"
                  />
                </aux-input>
                <v-divider />
                <v-row class="ma-1">
                  <v-col cols="12" sm="4">
                    <h5>Identificador*</h5>
                    <small>CIF, NIF, NIE</small>
                  </v-col>
                  <v-col>
                    <v-text-field
                      dense
                      hide-details="auto"
                      filled
                      placeholder="INTRODUZCA IDENTIFICADOR"
                      :rules="[
                        rules.ValidateSpanishID,
                        esEmpresa ? rules.req : true,
                      ]"
                      v-model="
                        contrato.datosRepresentante.identificadorRepresentante
                      "
                    />
                  </v-col>
                </v-row>
                <v-divider />
                <aux-input title="Colectivo">
                  <v-select
                    dense
                    hide-details="auto"
                    filled
                    label="Seleccione el colectivo"
                    v-model="contrato.datosRepresentante.colectivo"
                    :items="selects.representante.colectivos"
                    return-object
                    clearable
                    item-text="TextoColectivo"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Comentario">
                  <v-text-field
                    dense
                    hide-details="auto"
                    filled
                    placeholder="Introduzca el comentario"
                    v-model="contrato.datosRepresentante.comentario"
                  />
                </aux-input>
              </v-card>
            </v-col>
            <v-col cols="12" class="col-md-6">
              <v-card outlined>
                <v-sheet
                  :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'"
                  class="d-flex pa-4"
                >
                  <v-card-subtitle class="pa-0">
                    <v-icon small>mdi-pencil</v-icon>&nbsp;Otros datos
                  </v-card-subtitle>
                </v-sheet>
                <v-divider />
                <aux-input title="Periodo de lectura *">
                  <v-select
                    dense
                    hide-details="auto"
                    filled
                    label="Seleccione un periodo"
                    v-model="contrato.otrosDatos.periodoLectura"
                    :items="selects.otros.periodoLectura"
                    return-object
                    :rules="[rules.req]"
                    clearable
                    item-text="TextoPeriodo"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Tipo Impresión Factura *">
                  <v-select
                    dense
                    hide-details="auto"
                    filled
                    label="Seleccione un Tipo de Impresión"
                    v-model="contrato.otrosDatos.tipoImpresionFactura"
                    :rules="[rules.req]"
                    :items="selects.otros.tipoImpresionFactura"
                    item-text="Texto"
                    clearable
                    return-object
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Tipo Solicitud *">
                  <v-select
                    filled
                    dense
                    hide-details="auto"
                    label="Seleccione un Tipo de Solicitud"
                    v-model="contrato.otrosDatos.tipoSolicitud"
                    :rules="[rules.req]"
                    :items="selects.otros.tipoSolicitud"
                    item-text="NombreSolicitudTipo"
                    return-object
                    clearable
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Modelo contrato *">
                  <v-select
                    dense
                    hide-details="auto"
                    filled
                    label="Seleccione un modelo de contrato"
                    v-model="contrato.otrosDatos.modeloContrato"
                    :items="selects.otros.modeloContrato"
                    return-object
                    :rules="[rules.req]"
                    item-text="DescripcionModeloDeImpresion"
                    clearable
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Idioma contrato *">
                  <v-select
                    dense
                    hide-details="auto"
                    filled
                    label="Seleccione el idioma del contrato a aplicar"
                    v-model="contrato.otrosDatos.idiomaContrato"
                    :items="selects.otros.idiomas"
                    :rules="[rules.req]"
                    return-object
                    clearable
                    item-text="TextoIdioma"
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Fecha efecto *">
                  <v-select
                    dense
                    hide-details="auto"
                    filled
                    label="Seleccione una fecha"
                    v-model="contrato.otrosDatos.fechaEfecto"
                    return-object
                    clearable
                    item-text="TextoFechaEfecto"
                    :rules="[rules.req]"
                    :items="selects.otros.fechaEfecto"
                    @change="contrato.otrosDatos.activacionPrevista = null"
                  />
                </aux-input>
                <v-divider />
                <v-row class="ma-1">
                  <v-col cols="12" sm="4">
                    <h5>Activacion prevista</h5>
                    <small>
                      Solo disponible si se selecciona 'Según la fecha
                      solicitada'
                    </small>
                  </v-col>
                  <v-col>
                    <v-text-field
                      type="date"
                      dense
                      hide-details="auto"
                      filled
                      :disabled="
                        contrato.otrosDatos.fechaEfecto == null ||
                          !contrato.otrosDatos.fechaEfecto.FechaRequerida
                      "
                      v-model="contrato.otrosDatos.activacionPrevista"
                    />
                  </v-col>
                </v-row>
                <v-divider />
                <aux-input title="Duración del contrato">
                  <v-select
                    dense
                    hide-details="auto"
                    filled
                    label="Selecciona duración del contrato"
                    v-model="contrato.otrosDatos.duracionContrato"
                    :items="selects.otros.duracionContrato"
                    clearable
                  />
                </aux-input>
                <v-divider />
                <aux-input title="Codigo vendedor">
                  <v-select
                    dense
                    hide-details="auto"
                    filled
                    label="Seleccione un agente"
                    v-model="contrato.otrosDatos.codigoVendedor"
                  />
                </aux-input>
                <v-divider />
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <!-- TAB PRODUCTO FLEXIBLE -->

        <v-tab-item eager>
          <v-row>
            <v-col cols="12">
              <CrearFlexibleGas
                v-model="contrato.detallesContratacion"
                v-if="$store.getters.getEntorno == 'Gas'"
              />

              <v-card v-else outlined class="pa-3">
                <v-sheet
                  :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'"
                  class="d-flex pa-4"
                >
                  <v-card-subtitle class="pa-0">
                    <v-icon small>mdi-pencil</v-icon>&nbsp;PRODUCTO FLEXIBLE
                  </v-card-subtitle>
                  <v-spacer />
                </v-sheet>

                <template v-if="contrato.detallesContratacion.tarifa == null">
                  <v-card-title>
                    <v-spacer />Selecciona una tarifa
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-actions>
                    <v-row class="mb-2">
                      <v-col cols="12" class="col-md-6 mx-auto">
                        <v-select
                          filled
                          placeholder="Seleccione una tarifa de Acceso*"
                          dense
                          hide-details="auto"
                          v-model="contrato.detallesContratacion.tarifa"
                          clearable
                          :items="selects.contratacion.tarifas"
                          item-text="TextoTarifa"
                          :rules="[rules.req]"
                          return-object
                          @change="getProductoContratacion"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </template>

                <template
                  v-else-if="
                    esProductoFlexible && contrato.detallesContratacion.tarifa
                  "
                >
                  <v-divider />
                  <aux-input title="Tarifa">
                    <v-text-field
                      dense
                      hide-details="auto"
                      filled
                      :value="contrato.detallesContratacion.tarifa.TextoTarifa"
                      readonly
                    />
                  </aux-input>
                  <v-divider />
                  <aux-input title="Consumo total anual">
                    <v-text-field
                      dense
                      hide-details="auto"
                      filled
                      :value="contrato.detallesContratacion.consumoAnualSips"
                      readonly
                      suffix="KWh/año"
                    />
                  </aux-input>
                  <v-divider />
                  <v-row class="ma-1">
                    <v-col cols="12">
                      <h5>Potencia Contratada (KW)</h5>
                    </v-col>
                    <v-row class="pa-2">
                      <v-col
                        cols="12"
                        class="col-sm-6 col-md-4"
                        v-for="n in 6"
                        :key="n"
                      >
                        <v-text-field
                          dense
                          hide-details="auto"
                          filled
                          :value="contrato.detallesContratacion[`p${n}`]"
                          readonly
                          :label="`P${n}`"
                          v-show="contrato.detallesContratacion[`p${n}`]"
                        />
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-divider />
                  <aux-input title="Fee término de energía">
                    <v-select
                      dense
                      hide-details="auto"
                      filled
                      v-model="contrato.productoFlexible.feeEnergia"
                      :items="selects.flexible.feeEnergia"
                      :rules="[esProductoFlexible ? rules.req : true]"
                      suffix="EUR/MWh"
                    />
                  </aux-input>
                  <v-divider />
                  <v-row class="ma-1">
                    <v-col cols="12">
                      <h5>Incremento en término de potencia (EUR/KW y año)</h5>
                    </v-col>
                    <v-row class="pa-2">
                      <v-col
                        cols="12"
                        class="col-sm-6 col-md-4"
                        v-for="n in 6"
                        :key="n"
                      >
                        <v-select
                          dense
                          hide-details="auto"
                          filled
                          v-model="contrato.productoFlexible[`p${n}`]"
                          :label="`P${n}`"
                          suffix="€"
                          :items="selects.flexible.incrementoPotencia"
                          v-show="atrPotencia[`P${n}`]"
                          :rules="[atrPotencia[`p${n}`] ? rules.req : true]"
                        />
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-divider />
                  <v-row class="ma-1">
                    <v-col cols="12">
                      <h5>ATR potencia - €/kW y año - sin IE sin IVA</h5>
                    </v-col>
                    <v-row class="pa-2" v-if="atrPotencia">
                      <v-col
                        cols="12"
                        class="col-sm-6 col-md-4"
                        v-for="n in 6"
                        :key="n"
                      >
                        <v-text-field
                          dense
                          hide-details="auto"
                          filled
                          readonly
                          :value="atrPotencia[`P${n}`]"
                          :label="`P${n}`"
                          suffix="€"
                          v-show="atrPotencia[`P${n}`]"
                        />
                      </v-col>
                    </v-row>
                  </v-row>
                </template>

                <template v-else>
                  <v-card-title>
                    <v-spacer />
                    <h3>
                      Ya tienes un producto
                      {{
                        contrato.detallesContratacion.producto.TextoTarifaGrupo.includes(
                          "FLEXIBLE"
                        )
                          ? "flexible"
                          : ""
                      }}
                      seleccionado
                    </h3>
                    <v-spacer />
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="
                        () => {
                          $root.$emit('snack', `Producto deseleccionado`);
                          contrato.detallesContratacion.producto = null;
                        }
                      "
                      >Generar
                      {{
                        contrato.detallesContratacion.producto.TextoTarifaGrupo.includes(
                          "FLEXIBLE"
                        )
                          ? "otro"
                          : "un"
                      }}
                      producto flexible</v-btn
                    >
                    <v-spacer />
                  </v-card-actions>
                </template>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <!-- TAB DOCUMENTACION -->

        <v-tab-item eager v-if="$route.query.id">
          <v-row>
            <v-col cols="12">
              <v-card outlined>
                <v-sheet
                  :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'"
                  class="d-flex pa-4"
                >
                  <v-card-subtitle class="pa-0">
                    <v-icon small>mdi-file-multiple</v-icon>&nbsp;DOCUMENTACION
                  </v-card-subtitle>
                  <v-spacer />
                </v-sheet>
                <v-row no-gutters class="d-flex align-center mx-4 mt-4">
                  <v-col cols="12" sm>
                    <v-file-input
                      label="Adjuntar ficheros"
                      filled
                      hide-details="auto"
                      v-model="newFile"
                      dense
                      :rules="[
                        (value) =>
                          !value ||
                          value.size < 15000000 ||
                          'El archivo no puede superar las 15MB',
                      ]"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" sm="auto" class="ml-1 mt-1 mt-sm-0">
                    <v-btn
                      block
                      :disabled="!newFile || newFile.size > 15000000"
                      color="primary"
                      large
                      @click="subirDocumento"
                      :loading="sendingFile"
                    >
                      <v-icon left>mdi-upload</v-icon>Subir
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider class="my-4"></v-divider>
                <v-data-table :items="documentos" :headers="documentosHeader">
                  <template v-slot:item.acciones="{ item }">
                    <v-btn
                      @click="verDocumento(item.name, true)"
                      dark
                      text
                      color="secondary"
                      :loading="downloadingFile"
                    >
                      <v-icon left>mdi-download</v-icon>Descargar
                    </v-btn>
                    <v-btn
                      @click="verDocumento(item.name, false)"
                      dark
                      text
                      color="secondary"
                      :loading="downloadingFile"
                    >
                      <v-icon left>mdi-eye</v-icon>Ver
                    </v-btn>
                    <v-btn
                      color="error"
                      text
                      small
                      class="ml-1"
                      @click="borrarDocumento(item.name)"
                    >
                      <v-icon left>mdi-delete</v-icon>Borrar
                    </v-btn>
                  </template>
                  <template v-slot:item.name="{ item }">
                    <v-chip
                      color="transparent"
                      text-color="secondary"
                      class="text-button"
                      outlined
                      label
                      >{{ item.name }}</v-chip
                    >
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-form>
    <!-- {{selects.contratacion.perfilFacturacion}} -->

    <v-bottom-sheet v-model="changeFileName.show" persistent>
      <v-card class="pa-4">
        <v-row>
          <v-col cols="12" class="col-sm-8 col-md-6 mx-auto">
            <v-card-title>¿Con qué nombre quieres guardarlo?</v-card-title>
            <v-text-field
              v-model="changeFileName.newName"
              filled
              dense
              hide-details="auto"
              :suffix="changeFileName.extension"
              :rules="[changeFileName.req]"
            ></v-text-field>
            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" @click="changeFileName.reject(false)" text
                >Cancelar</v-btn
              >
              <v-btn color="primary" @click="changeFileName.resolve(true)" text
                >Aceptar</v-btn
              >
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet :value="checksPanel" inset persistent>
      <v-card>
        <!-- <v-subheader>Beta</v-subheader> -->
        <!-- <v-divider></v-divider> -->
        <!-- <v-list>
					<v-slide-x-transition group>
						<v-list-item v-for="(value, key) in checks" :key="key">
							<v-row class="align-center">
								<v-col cols="auto">
									<v-icon v-if="value.progress === false">mdi-minus</v-icon>
									<v-icon color="success" v-else-if="value.progress === true"
										>mdi-check</v-icon
									>
									<v-icon color="error" v-else-if="value.progress === 'error'"
										>mdi-close-octagon-outline</v-icon
									>
									<v-progress-circular
										v-else-if="value.progress === 'loading'"
										indeterminate
										color="secondary"
									></v-progress-circular>
									<v-progress-circular
										v-else
										:value="(value.value * 100) / value.max"
										color="secondary"
									></v-progress-circular>
								</v-col>
								<v-col>
									<h4
										:class="[
											value.progress == 'error' ? 'error--text' : '',
											value.progress === true ? 'success--text' : '',
										]"
									>
										{{ value.texto }}
									</h4>
								</v-col>
							</v-row>
						</v-list-item>
					</v-slide-x-transition>
				</v-list>-->
        <v-timeline dense>
          <v-slide-x-transition group>
            <v-timeline-item
              fill-dot
              color="transparent"
              class="align-center"
              v-for="(value, key) in checks"
              :key="key"
            >
              <template v-slot:icon>
                <v-progress-circular
                  v-if="
                    !isNaN(value.max) &&
                      (value.progress == null || value.progress == undefined)
                  "
                  :value="(value.actual * 100) / value.max"
                  color="secondary"
                ></v-progress-circular>
                <v-icon v-else-if="value.progress === false">mdi-minus</v-icon>
                <v-icon color="success" v-else-if="value.progress === true"
                  >mdi-check</v-icon
                >
                <v-icon color="error" v-else-if="value.progress === 'error'"
                  >mdi-close-octagon-outline</v-icon
                >
                <v-progress-circular
                  v-else-if="value.progress === 'loading'"
                  indeterminate
                  color="secondary"
                ></v-progress-circular>
              </template>
              <h4
                :class="[
                  value.progress == 'error' ? 'error--text' : '',
                  value.progress === true ? 'success--text' : '',
                  value.progress === false ? 'grey--text' : '',
                ]"
              >
                {{ value.texto }}
              </h4>
            </v-timeline-item>
          </v-slide-x-transition>
        </v-timeline>
      </v-card>
    </v-bottom-sheet>

    <v-dialog
      v-model="unsavedChanges.show"
      max-width="700"
      width="100%"
      persistent
    >
      <v-card>
        <v-card-title>
          Tienes cambios sin guardar ¿Estás seguro de que quieres salir?
        </v-card-title>
        <v-card-actions class="mt-8 flex-wrap">
          <v-btn @click="unsavedChanges.reject(false)" class="flex-grow-1" text
            >Cancelar</v-btn
          >
          <v-btn
            @click="unsavedChanges.reject(true)"
            class="flex-grow-1"
            color="error"
            text
            >Salir sin guardar</v-btn
          >
          <v-btn
            @click="unsavedChanges.resolve()"
            class="flex-grow-1"
            color="primary"
            text
            >Guardar y salir</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import axios from "redaxios";
import {
  cups,
  ValidateSpanishID,
  email,
  req,
  isNumber,
  spainIdType,
  iban,
} from "@/utils/validations.js";
import {
  processToken,
  calcularConsumoAnual,
  calcularConsumoAnualGas,
  obtenerNombreTarifaML,
  obtenerNombreTarifaGas,
  decode64,
  parseDate,
  humanFileSize,
  downloadFile,
} from "@/utils/index.js";
import extensions from "@/utils/mimeExtensions.js";

export default {
  components: {
    AuxInput: () => import("@/components/AuxInput.vue"),
    CrearFlexibleGas: () => import("../components/CrearFlexibleGas.vue"),
  },
  data() {
    return {
      unsavedChanges: {
        show: false,
        promise: null,
        reject: null,
        resolve: null,
      },
      checksPanel: false,
      hasChanged: false,
      cargandoContrato: false,
      esBorrador: true,
      errors: null,
      incidencias: [],
      tab: 0,
      newFile: null,
      sendingFile: false,
      downloadingFile: false,
      generatingPDF: false,
      changeFileName: {
        newName: "",
        extension: "",
        show: false,
        resolve: null,
        reject: null,
        req,
      },
      documentos: [],
      documentosHeader: [
        { text: "Nombre", value: "name" },
        { text: "Acciones", value: "acciones", sortable: false },
      ],
      contrato: {
        puntoSuministro: {
          cups: "",
          codDistribuidora: null,
          codComercializadora: 1017, //? PUESTO A PEDAL,
          sugerenciaCiudad: null,
          ciudad: null,
          provincia: null,
          calle: null,
          numero: null,
          aclarador: null,
          codPostal: null,
          ficheroAdjunto: null,
        },
        detallesContratacion: {
          tarifa: null,
          producto: null,
          perfilFacturacion: null,
          p1: "",
          p2: "",
          p3: "",
          p4: "",
          p5: "",
          p6: "",
          consumoAnualSips: null,
          ConsumoEstimadoP1: null,
          ConsumoEstimadoP2: null,
          ConsumoEstimadoP3: null,
          ConsumoEstimadoP4: null,
          ConsumoEstimadoP5: null,
          ConsumoEstimadoP6: null,
        },
        serviciosValor: {
          grupoProducto: null,
          producto: null,
        },
        datosTitular: {
          identificador: "",
          nombre: null,
          apellido1: null,
          apellido2: null,
          razonSocial: null,
          ciudad: null,
          provincia: null,
          calle: null,
          numero: null,
          aclarador: null,
          codPostal: null,
          telefonoFijo: null,
          telefonoMovil: null,
          email: null,
        },
        datosEnvio: {
          nombre: null,
          ciudad: null,
          provincia: null,
          calle: null,
          numero: null,
          aclarador: null,
          codPostal: null,
        },
        observaciones: null,
        datosBancarios: {
          nombrePagador: null,
          identificador: null,
          tipoCobro: null,
          iban: null,
          bic: null,
          cnae: null,
        },
        otrosDatos: {
          periodoLectura: null,
          tipoImpresionFactura: null,
          tipoSolicitud: null,
          modeloContrato: null,
          idiomaContrato: null,
          fechaEfecto: null,
          activacionPrevista: null,
          duracionContrato: null,
          codigoVendedor: null,
        },
        datosRepresentante: {
          nombreRepresentante: null,
          emailRepresentante: null,
          telefonoRepresentante: null,
          identificador: null,
          comentario: null,
          colectivo: null,
        },
        productoFlexible: {
          feeEnergia: 0.25,
          p1: 0,
          p2: 0,
          p3: 0,
          p4: 0,
          p5: 0,
          p6: 0,
        },
        certificadoElectronico: false,
        enviarSMS: false,
        enviarEmail: false,
        empresa: null,
        residencial: false,
      },
      datosFlexible: {},
      selects: {
        serviciosValor: {
          grupoProducto: [],
          producto: [],
        },
        contratacion: {
          tarifas: [],
          producto: [],
          perfilFacturacion: [],
        },
        bancarios: {
          tipoCobro: [],
          cnae: [],
        },
        otros: {
          periodoLectura: [],
          tipoSolicitud: [],
          modeloContrato: [],
          idiomas: [],
          fechaEfecto: [],
          tipoImpresionFactura: [
            {
              Valor: "P",
              Texto: "Papel & email",
            },
            {
              Valor: "Q",
              Texto: "Papel",
            },
            {
              Valor: "E",
              Texto: "Email",
            },
            {
              Valor: "W",
              Texto: "Web",
            },
            {
              Valor: "R",
              Texto: "Recibo Bancario",
            },
          ],
          duracionContrato: [
            "12 meses",
            "24 meses",
            "36 meses",
            "48 meses",
            "60 meses",
          ],
        },
        representante: {
          colectivos: [],
        },
        flexible: {
          feeEnergia: [],
          incrementoPotencia: [],
        },
      },

      ciudadesPosibles: {
        puntoSuministro: [],
        datosTitular: [],
        datosEnvio: [],
      },
      callesPosibles: { puntoSuministro: [], datosTitular: [], datosEnvio: [] },

      detallesFlexible: {
        show: 0,
      },

      checks: {},

      busqueda: {
        ciudad: {
          puntoSuministro: null,
          datosTitular: null,
          datosEnvio: null,
        },
        calle: {
          puntoSuministro: null,
          datosTitular: null,
          datosEnvio: null,
        },
      },

      isLoading: false,
      isLoadingCalle: false,

      loadingData: true,

      ciudadFound: false,

      flexibleName: null,

      debouncer: {},

      idEmpresa: null,
      idUsuario: null,
      idAgente: null,

      NombreAgente: this.$store.getters.getDatosEmpresa.iss.NombreAgente,
    };
  },
  computed: {
    esEmpresa() {
      return (
        this.contrato.datosTitular.identificador != null &&
        spainIdType(this.contrato.datosTitular.identificador) == "cif"
      );
    },
    esProductoFlexible() {
      // if (this.$store.getters.getEntorno == "Gas") return false;
      if (this.contrato.detallesContratacion.tarifa == null) return false;
      return this.contrato.detallesContratacion.producto == null;
    },
    rules() {
      return {
        cups,
        ValidateSpanishID,
        email,
        isNumber,
        iban,
        req: (value) => (!this.esBorrador ? req(value) : true),
      };
    },
  },
  asyncComputed: {
    async atrPotencia() {
      if (
        !this.esProductoFlexible ||
        !this.contrato.detallesContratacion.tarifa ||
        this.checksPanel
      )
        return {};
      if (this.$store.getters.getEntorno == "Gas") return;

      let tarifa = this.contrato.detallesContratacion.tarifa.TextoTarifa.replaceAll(
        / ML/g,
        ""
      );
      let _ = this.contrato.productoFlexible.p1;
      _ = this.contrato.productoFlexible.p2;
      _ = this.contrato.productoFlexible.p3;
      _ = this.contrato.productoFlexible.p4;
      _ = this.contrato.productoFlexible.p5;
      _ = this.contrato.productoFlexible.p6;

      let { data: preciosBOE } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OUR_API_URL}/altaproducto.php`,
        params: {
          token: this.$store.getters.getJwtEmpresa,
          tarifa,
        },
      });

      let boe = {};
      Object.entries(preciosBOE).forEach(([key, value]) => {
        if (/(P|p)/.test(key) && Number(value) > 0) {
          boe[key] =
            isNaN(value) ||
            Number(parseFloat(value).toFixed(6)) +
              Number(this.contrato.productoFlexible[`p${key[1]}`] || 0);
        }
      });

      return boe;
    },
  },
  methods: {
    getDatosBorrador() {
      return new Promise(async (resolve, reject) => {
        try {
          let { data } = await axios({
            method: "get",
            url: `${process.env.VUE_APP_OUR_API_URL}/contratos.php`,
            params: {
              id: this.$route.query.id,
              token: this.$store.getters.getJwtEmpresa,
            },
          });
          data = data[0];

          if (data.Estado != "Borrador") {
            this.$root.$emit(
              "snack",
              "Este contrato ya no es un borrador, no se permite su edición"
            );
            this.$router.go(-1);
            return;
          }

          if (data.entorno != this.$store.getters.getEntorno) {
            this.$root.$emit(
              "snack",
              `Este borrador es de ${data.entorno} y estás en ${this.$store.getters.getEntorno}`
            );
            this.$router.go(-1);
            return;
          }

          this.idUsuario = data.IdUsuario;
          if (data.NombreAgente) this.NombreAgente = data.NombreAgente;

          this.checks.ciudades.progress = "loading";

          let promesasCiudades = [];
          if (data.CiudadSuministro != "" && data.CiudadSuministro != null)
            promesasCiudades.push(
              this.buscarCiudad(data.CiudadSuministro, "puntoSuministro")
            );
          if (data.CiudadTitular != "" && data.CiudadTitular != null)
            promesasCiudades.push(
              this.buscarCiudad(data.CiudadTitular, "datosTitular")
            );
          if (data.CiudadEnvio != "" && data.CiudadEnvio != null)
            promesasCiudades.push(
              this.buscarCiudad(data.CiudadEnvio, "datosEnvio")
            );

          await Promise.all(promesasCiudades).catch((e) => {
            console.error(e);
            this.checks.ciudades.progress = "error";
            this.cargandoContrato = false;
            reject();
            const self = this;
            setTimeout(() => {
              self.$router.go(-1);
            }, 1500);
            return;
          });
          this.checks.ciudades.progress = true;

          this.contrato.puntoSuministro.ciudad = this.ciudadesPosibles.puntoSuministro.find(
            (ciudad) =>
              ciudad.desCiudad == data.CiudadSuministro &&
              ciudad.codigoProveedorMunicipio ==
                data.CodigoProveedorMunicipioSuministro
          );
          this.contrato.datosTitular.ciudad = this.ciudadesPosibles.datosTitular.find(
            (ciudad) =>
              ciudad.desCiudad == data.CiudadTitular &&
              ciudad.codigoProveedorMunicipio ==
                data.CodigoProveedorMunicipioTitular
          );
          this.contrato.datosEnvio.ciudad = this.ciudadesPosibles.datosEnvio.find(
            (ciudad) =>
              ciudad.desCiudad == data.CiudadEnvio &&
              ciudad.codigoProveedorMunicipio ==
                data.CodigoProveedorMunicipioEnvio
          );

          this.checks.calles.progress = "loading";

          let promesasCalles = [];
          if (this.contrato.puntoSuministro.ciudad != null)
            promesasCalles.push(this.buscarCalle("puntoSuministro"));
          if (this.contrato.datosTitular.ciudad != null)
            promesasCalles.push(this.buscarCalle("datosTitular"));
          if (this.contrato.datosEnvio.ciudad != null)
            promesasCalles.push(this.buscarCalle("datosEnvio"));

          await Promise.all(promesasCalles).catch((e) => {
            console.error(e);
            this.checks.calles.progress = "error";
            this.cargandoContrato = false;
            reject();
            const self = this;
            setTimeout(() => {
              self.$router.go(-1);
            }, 1500);
            return;
          });

          this.contrato.detallesContratacion.tarifa = this.selects.contratacion.tarifas.find(
            (tarifa) => tarifa.TextoTarifa == data.Tarifa
          );
          if (this.contrato.detallesContratacion.tarifa != null) {
            await this.getProductoContratacion(
              this.contrato.detallesContratacion.tarifa
            );
          }

          this.checks.borrador.progress = "loading";

          this.contrato = {
            estado: data.estado,
            empresa: data.empresa,
            enviarSMS: Boolean(Number(data.enviarSMS)),
            enviarEmail: Boolean(Number(data.enviarEmail)),
            residencial: Boolean(Number(data.residencial)),
            idUsuarioBorrador: data.IdUsuarioBorrador,
            puntoSuministro: {
              ...this.contrato.puntoSuministro,
              cups: data.CodigoCUPS,
              codDistribuidora: data.CodigoREEDistribuidora,
              codComercializadora: 1017,
              sugerenciaCiudad: data.CiudadSuministro,
              provincia: data.ProvinciaSuministro,
              calle: this.callesPosibles.puntoSuministro.find(
                (calle) => calle.desCalle == data.CallePuntoSuministro
              ),
              numero: data.Numero,
              aclarador: data.Aclarador,
              codPostal: data.CodPostal,
              ficheroAdjunto: null,
            },
            detallesContratacion: {
              ...this.contrato.detallesContratacion,
              producto:
                this.selects.contratacion.producto.find(
                  (producto) => producto.TextoTarifaGrupo == data.TarifaGrupo
                ) || null,
              perfilFacturacion:
                this.selects.contratacion.perfilFacturacion.find(
                  (perfil) =>
                    perfil.TextoPerfilFacturacion ==
                    data.PerfilFacturacionContratoTarifa
                ) || this.selects.contratacion.perfilFacturacion[0],
              p1: data.P1,
              p2: data.P2,
              p3: data.P3,
              p4: data.P4,
              p5: data.P5,
              p6: data.P6,
              consumoAnualSips: data.ConsumoEstimado,
              ConsumoEstimadoP1: data.ConsumoEstimadoP1,
              ConsumoEstimadoP2: data.ConsumoEstimadoP2,
              ConsumoEstimadoP3: data.ConsumoEstimadoP3,
              ConsumoEstimadoP4: data.ConsumoEstimadoP4,
              ConsumoEstimadoP5: data.ConsumoEstimadoP5,
              ConsumoEstimadoP6: data.ConsumoEstimadoP6,
            },
            serviciosValor: {
              grupoProducto: null,
              producto: data.Producto,
            },
            datosTitular: {
              ...this.contrato.datosTitular,
              identificador: data.Identificador,
              nombre: data.NombreTitular,
              apellido1: data.Apellido1Titular,
              apellido2: data.Apellido2Titular,
              razonSocial: data.RazonSocialTitular,
              provincia: data.ProvinciaTitular,
              calle: this.callesPosibles.datosTitular.find(
                (calle) => calle.desCalle == data.CalleTitular
              ),
              numero: data.NumeroTitular,
              aclarador: data.AclaradorTitular,
              codPostal: data.CPTitular,
              telefonoFijo: data.TfnoTitular,
              telefonoMovil: data.Movil,
              email: data.EmailTitular,
            },
            datosEnvio: {
              ...this.contrato.datosEnvio,
              nombre: data.NombreEnvio,
              provincia: data.ProvinciaEnvio,
              calle: this.callesPosibles.datosEnvio.find(
                (calle) => calle.desCalle == data.CalleEnvio
              ),
              numero: data.NumeroEnvio,
              aclarador: data.AclaradorEnvio,
              codPostal: data.CPEnvio,
            },
            observaciones: data.Observaciones,
            datosBancarios: {
              nombrePagador: data.NombrePagador,
              identificador: data.IdentificadorPagador,
              tipoCobro: this.selects.bancarios.tipoCobro.find(
                (tipo) => tipo.TextoTipoCobro == data.TextoTipoCobro
              ),
              iban: data.IBAN,
              bic: data.BIC,
              cnae: this.selects.bancarios.cnae.find(
                (cnae) => cnae.CodigoCNAE == data.CodigoCNAE
              ),
            },
            otrosDatos: {
              periodoLectura: this.selects.otros.periodoLectura.find(
                (periodo) => periodo.TextoPeriodo == data.TextoPeriodo
              ),
              tipoImpresionFactura: this.selects.otros.tipoImpresionFactura.find(
                (tipo) => tipo.Valor == data.TextoTipoImpresion
              ),
              tipoSolicitud: this.selects.otros.tipoSolicitud.find(
                (tipo) => tipo.OperacionAsociada == data.TipoSolicitud
              ),
              modeloContrato: this.selects.otros.modeloContrato.find(
                (modelo) =>
                  modelo.IdModeloDeImpresion == data.IdModeloContrato &&
                  modelo.DescripcionModeloDeImpresion ==
                    data.TextoModeloContrato
              ),
              idiomaContrato: this.selects.otros.idiomas.find(
                (idioma) => idioma.IdIdiomaInforme == data.IdIdiomaInforme
              ),
              fechaEfecto: this.selects.otros.fechaEfecto.find(
                (fecha) => fecha.IdSolicitudTipoFechaEfecto == data.FechaEfecto
              ),
              duracionContrato: this.selects.otros.duracionContrato.find(
                (d) => d == data.duracionContrato
              ),
              activacionPrevista: data.ActivacionPrevista,
              codigoVendedor: data.CodigoVendedor,
            },
            datosRepresentante: {
              nombreRepresentante: data.Representante,
              emailRepresentante: data.EmailRep,
              telefonoRepresentante: data.SMSRep,
              identificadorRepresentante: data.IdentificadorRep,
              comentario: data.Comentario,
              colectivo: this.selects.representante.colectivos.find(
                (colectivo) => colectivo.IdColectivo == data.IdColectivoRep
              ),
            },
            productoFlexible: {
              feeEnergia: null,
              p1: null,
              p2: null,
              p3: null,
            },
            certificadoElectronico: !!data.IsFirmaDigital,
          };

          if (this.contrato.datosTitular.Identificador != null) {
            this.buscarCliente(false);
          }

          this.checks.borrador.progress = true;

          resolve({ flexible: data.TarifaGrupo });
        } catch (e) {
          reject(e);
          console.log(e);
        }
      });
    },
    buscarCups() {
      if (
        this.contrato.puntoSuministro.cups == "" ||
        this.contrato.puntoSuministro.cups == null
      )
        return;
      axios({
        url:
          this.$store.getters.getEntorno == "Luz"
            ? "https://sips.sigeenergia.com:61843/SIPSAPIvLast/api/v2/ClientesSips/GetClientesPost"
            : "https://sips.sigeenergia.com:61843/SIPSAPIvLast/api/v2/ClientesSipsGas/GetClientesPost",
        method: "POST",
        data: {
          CodigoCUPS: this.contrato.puntoSuministro.cups,
          NombreEmpresaDistribuidora: "",
          MunicipioPS: "",
          CodigoProvinciaPS: "",
          CodigoPostalPS: this.cp,
          CodigoTarifaATREnVigor: "",
          ListCUPS: "",
          LoadAllDatosCliente: true,
          LoadConsumos: true,
          IsExist: true,
        },
      }).then((res) => {
        if (
          "004" == res.data.ClientesSips[0].CodigoTarifaATREnVigor ||
          "006" == res.data.ClientesSips[0].CodigoTarifaATREnVigor
        ) {
          res.data.ClientesSips[0].PotenciasContratadasEnWP3 =
            res.data.ClientesSips[0].PotenciasContratadasEnWP1;
          res.data.ClientesSips[0].PotenciasContratadasEnWP2 = null;
        }

        let i = this.contrato.puntoSuministro.cups.substring(2, 8);
        let dist;
        "003110" == i
          ? (dist = "0023")
          : "003130" == i
          ? (dist = "0029")
          : "003144" == i
          ? (dist = "0396")
          : "003150" == i
          ? (dist = "0288")
          : "003160" == i
          ? (dist = "0363")
          : "003140" == i
          ? res.data.ClientesSips.length > 0 &&
            res.data.ClientesSips[0].CodigoPostalPS.startsWith("50")
            ? (dist = "0029")
            : (dist = "0024")
          : (dist = this.contrato.puntoSuministro.cups.substring(2, 6));

        let aux = res.data.ClientesSips[0];

        if (this.$store.getters.getEntorno == "Luz")
          var tarifaBOE = obtenerNombreTarifaML(aux.CodigoTarifaATREnVigor)
            .actual;
        else var tarifaBOE = obtenerNombreTarifaGas(aux.CodigoPeajeEnVigor);

        this.contrato.detallesContratacion.tarifa = this.selects.contratacion.tarifas.find(
          (tarifa) => tarifa.TextoTarifa == tarifaBOE
        );

        this.getProductoContratacion(this.contrato.detallesContratacion.tarifa);

        if (tarifaBOE == "2.0TD ML") {
          if (
            aux.PotenciasContratadasEnWP1 == null ||
            aux.PotenciasContratadasEnWP1 == 0
          ) {
            if (
              aux.PotenciasContratadasEnWP2 != null ||
              aux.PotenciasContratadasEnWP2 != 0
            ) {
              aux.PotenciasContratadasEnWP1 = aux.PotenciasContratadasEnWP2;
              aux.PotenciasContratadasEnWP2 = 0;
              if (
                aux.PotenciasContratadasEnWP3 == null ||
                aux.PotenciasContratadasEnWP3 == 0
              ) {
                aux.PotenciasContratadasEnWP3 = aux.PotenciasContratadasEnWP1;
              }
            }
          } else if (
            aux.PotenciasContratadasEnWP3 == null ||
            aux.PotenciasContratadasEnWP3 == 0
          ) {
            aux.PotenciasContratadasEnWP3 = aux.PotenciasContratadasEnWP1;
          }
          aux.PotenciasContratadasEnWP2 = 0;
          aux.PotenciasContratadasEnWP4 = null;
          aux.PotenciasContratadasEnWP5 = null;
          aux.PotenciasContratadasEnWP6 = null;
        }
        if (tarifaBOE == "3.0TD ML" || tarifaBOE == "6.1TD ML") {
          if (
            (aux.PotenciasContratadasEnWP4 == null ||
              aux.PotenciasContratadasEnWP4 == 0) &&
            (aux.PotenciasContratadasEnWP5 == null ||
              aux.PotenciasContratadasEnWP5 == 0) &&
            (aux.PotenciasContratadasEnWP6 == null ||
              aux.PotenciasContratadasEnWP6 == 0)
          ) {
            aux.PotenciasContratadasEnWP6 = aux.PotenciasContratadasEnWP3;
            aux.PotenciasContratadasEnWP3 = aux.PotenciasContratadasEnWP2;
            aux.PotenciasContratadasEnWP4 = aux.PotenciasContratadasEnWP2;
            aux.PotenciasContratadasEnWP5 = aux.PotenciasContratadasEnWP2;
          }
        }
        res.data.ClientesSips[i] = aux;

        this.contrato.puntoSuministro.codDistribuidora = dist;
        this.contrato.puntoSuministro.sugerenciaCiudad = `${aux.DesMunicipioPS} (${aux.DesProvinciaPS})`;
        if (res.data.DatosTitular && res.data.DatosTitular.length > 0)
          this.contrato.puntoSuministro.sugerenciaCalle =
            res.data.DatosTitular[0].DireccionSuministro;
        this.contrato.puntoSuministro.codPostal = aux.CodigoPostalPS;
        this.contrato.detallesContratacion.p1 = aux.PotenciasContratadasEnWP1;
        this.contrato.detallesContratacion.p2 = aux.PotenciasContratadasEnWP2;
        this.contrato.detallesContratacion.p3 = aux.PotenciasContratadasEnWP3;
        this.contrato.detallesContratacion.p4 = aux.PotenciasContratadasEnWP4;
        this.contrato.detallesContratacion.p5 = aux.PotenciasContratadasEnWP5;
        this.contrato.detallesContratacion.p6 = aux.PotenciasContratadasEnWP6;

        if (this.$store.getters.getEntorno == "Luz")
          var consumosEstimados = calcularConsumoAnual(
            res.data.ConsumosSips,
            this.contrato.puntoSuministro.cups,
            res.data.ClientesSips[0].CodigoTarifaATREnVigor
          );
        else
          var consumosEstimados = calcularConsumoAnualGas(
            res.data.ConsumosSips,
            this.contrato.puntoSuministro.cups,
            res.data.ClientesSips[0].CodigoTarifaATREnVigor
          );

        this.contrato.detallesContratacion.ConsumoEstimadoP1 =
          consumosEstimados.a1;
        this.contrato.detallesContratacion.ConsumoEstimadoP2 =
          consumosEstimados.a2;
        this.contrato.detallesContratacion.ConsumoEstimadoP3 =
          consumosEstimados.a3;
        this.contrato.detallesContratacion.ConsumoEstimadoP4 =
          consumosEstimados.a4;
        this.contrato.detallesContratacion.ConsumoEstimadoP5 =
          consumosEstimados.a5;
        this.contrato.detallesContratacion.ConsumoEstimadoP6 =
          consumosEstimados.a6;

        this.contrato.detallesContratacion.consumoAnualSips =
          consumosEstimados.total ||
          consumosEstimados.auxCaudalMedioEnWhAnual * 1000;
      });
    },
    buscarCliente(validate = false) {
      return new Promise(async (resolve, reject) => {
        let { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}/Cliente/GetClientePorDni/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}/Dni/${this.contrato.datosTitular.identificador}`,
          headers: {
            Authorization: this.$store.getters.getToken,
          },
        });
        let cliente = processToken(data).iss;
        if (validate) {
          resolve(cliente);
          return;
        }
        this.contrato.datosTitular.nombre = cliente.Nombre;
        this.contrato.datosTitular.apellido1 = cliente.Apellido1;
        this.contrato.datosTitular.apellido2 = cliente.Apellido2;
        this.contrato.datosTitular.razonSocial = cliente.RazonSocial;
        this.contrato.datosTitular.sugerenciaCiudad = cliente.Ciudad;
        this.contrato.datosTitular.sugerenciaCalle = cliente.Calle;
        this.contrato.datosTitular.provincia = cliente.Provincia;
        this.contrato.datosTitular.aclarador = cliente.Aclarador;
        this.contrato.datosTitular.codPostal = cliente.CodPostal;
        this.contrato.datosTitular.numero = cliente.Numero;
        try {
          this.contrato.datosTitular.telefonoMovil = cliente.ClientesContacto.find(
            (item) => item.TipoContacto == "M"
          ).Valor;
        } catch {}
        try {
          this.contrato.datosTitular.telefonoFijo = cliente.ClientesContacto.find(
            (item) => item.TipoContacto == "F"
          ).Valor;
        } catch {}
        try {
          this.contrato.datosTitular.email = cliente.ClientesContacto.find(
            (item) => item.TipoContacto == "E"
          ).Valor;
        } catch {}
        this.contrato.datosTitular.numero = cliente.Numero;
        resolve();
      });
    },
    buscarCiudad(filtro, target = "puntoSuministro") {
      return new Promise((resolve, reject) => {
        clearTimeout(this.debouncer[target]);
        this.debouncer[target] = setTimeout(() => {
          let aux = filtro
            .replace(/ *\(.*\)/g, "")
            .split(/[^\x00-\x7F]/g)
            .sort((a, b) => b.length - a.length)[0]
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

          axios({
            url:
              "https://apicallejero.portalswitching.com/api/v1/Ciudad/GetDTOALLbyTexto",
            method: "POST",
            data: [
              {
                FiltroTexto: aux,
              },
            ],
          })
            .then((res) => {
              this.ciudadesPosibles[target] = res.data.map((item) => {
                item.texto = `${item.desCiudad} (${item.desProvincia})`;
                return item;
              });
              this.isLoading = false;
              resolve();
            })
            .catch((err) => reject(err));
        }, 500);
      });
    },
    ciudadCambio(target = "puntoSuministro") {
      if (this.contrato[target].ciudad == null) return;
      this.contrato[target].provincia = this.contrato[
        target
      ].ciudad.desProvincia;

      this.buscarCalle(target);

      this.ciudadesPosibles[target] = [this.contrato[target].ciudad];

      this.ciudadFound = true;
    },
    buscarCalle(target = "puntoSuministro") {
      return new Promise((resolve, reject) => {
        let { codMunicipio, codProvincia } = this.contrato[target].ciudad;
        axios({
          url: `https://apicallejero.portalswitching.com/api/v1/Calle/GetDTOALLCalles/CodigoMunicipio/${codMunicipio}/CodProvincia/${codProvincia}`,
          method: "GET",
        })
          .then((res) => {
            let temp = res.data.map((item) => {
              item.texto = `${item.desCalle} (${item.codigoPostal})`;
              return item;
            });

            this.callesPosibles[target] = temp;
            this.isLoadingCalle = false;
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    calleCambio(target = "puntoSuministro") {
      if (this.contrato[target].calle == null) return;

      this.contrato[target].codPostal = this.contrato[
        target
      ].calle.codigoPostal;
    },
    duplicarEnContrato() {
      this.contrato.datosTitular.ciudad = this.contrato.puntoSuministro.ciudad;
      this.contrato.datosTitular.provincia = this.contrato.puntoSuministro.provincia;
      this.contrato.datosTitular.calle = this.contrato.puntoSuministro.calle;
      this.contrato.datosTitular.numero = this.contrato.puntoSuministro.numero;
      this.contrato.datosTitular.codPostal = this.contrato.puntoSuministro.codPostal;
      this.contrato.datosTitular.aclarador = this.contrato.puntoSuministro.aclarador;
      this.callesPosibles.datosTitular = this.callesPosibles.puntoSuministro;
      this.ciudadesPosibles.datosTitular = this.ciudadesPosibles.puntoSuministro;
    },
    duplicarEnEnvioYOtros() {
      this.contrato.datosEnvio.ciudad = this.contrato.datosTitular.ciudad;
      this.contrato.datosEnvio.provincia = this.contrato.datosTitular.provincia;
      this.contrato.datosEnvio.calle = this.contrato.datosTitular.calle;
      this.contrato.datosEnvio.numero = this.contrato.datosTitular.numero;
      this.contrato.datosEnvio.aclarador = this.contrato.datosTitular.aclarador;
      this.contrato.datosEnvio.codPostal = this.contrato.datosTitular.codPostal;
      this.callesPosibles.datosEnvio = this.callesPosibles.datosTitular;
      this.ciudadesPosibles.datosEnvio = this.ciudadesPosibles.datosTitular;

      if (!this.esEmpresa) {
        let { nombre, apellido1, apellido2 } = this.contrato.datosTitular;
        var nombreCompleto = [nombre, apellido1, apellido2]
          .filter((item) => !!item)
          .join(" ");
      } else {
        var nombreCompleto = this.contrato.datosTitular.razonSocial;
      }

      this.contrato.datosBancarios.identificador = this.contrato.datosTitular.identificador;
      this.contrato.datosEnvio.nombre = nombreCompleto;
      this.contrato.datosBancarios.nombrePagador = nombreCompleto;
      this.contrato.datosEnvio.identificador = this.contrato.datosBancarios.identificador;
      // this.contrato.datosEnvio.identificador = this.contrato.datosBancarios.identificador;
    },
    getGrupoProductoServicios() {
      return new Promise((resolve, reject) => {
        axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}/GrupoProducto/GetAllProductoGrupo/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}`,
          headers: {
            Authorization: this.$store.getters.getToken,
          },
        })
          .then((res) => {
            this.selects.serviciosValor.grupoProducto = processToken(
              res.data
            ).iss;
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    getProductoServicios(value) {
      return new Promise((resolve, reject) => {
        if (value == null) {
          this.selects.serviciosValor.producto = [];
          return;
        }
        axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}/Producto/GetAllProducto/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}/IdProductoGrupo/${value.IdProductoGrupo}`,
          headers: {
            Authorization: this.$store.getters.getToken,
          },
        })
          .then((res) => {
            this.selects.serviciosValor.producto = processToken(res.data).iss;
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    getTarifasContratacion() {
      return new Promise((resolve, reject) => {
        axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}/Tarifa/GetDTOAll/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}`,
          headers: {
            Authorization: this.$store.getters.getToken,
          },
        })
          .then((res) => {
            if (this.$store.getters.getEntorno == "Luz")
              this.selects.contratacion.tarifas = processToken(
                res.data
              ).iss.filter((t) => t.TextoTarifa.includes("TD"));
            else this.selects.contratacion.tarifas = processToken(res.data).iss;
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    getProductoContratacion(value) {
      return new Promise((resolve, reject) => {
        if (value == null) {
          this.selects.contratacion.producto = [];
          return;
        }

        // console.log(value);
        axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}/Tarifa/GetDTOAllGruposTarifa/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}/Tarifa/${value.IdTarifa}`,
          headers: {
            Authorization: this.$store.getters.getToken,
          },
        })
          .then((res) => {
            this.selects.contratacion.producto = processToken(
              res.data
            ).iss.filter(({ TextoTarifaGrupo }) => {
              if (this.$store.getters.getEntorno == "Gas") return true;

              let empresa = this.$store.getters.getNombreEmpresa.toUpperCase();
              if (empresa == "ALUMBRAENERGIA") empresa = "ALUMBRA";

              return (
                !TextoTarifaGrupo.toUpperCase().includes("General") &&
                !TextoTarifaGrupo.toUpperCase().includes("Empleados") &&
                (TextoTarifaGrupo.toUpperCase().includes(empresa) ||
                  TextoTarifaGrupo == "PRODUCTO WEB") &&
                (TextoTarifaGrupo.toUpperCase() == this.flexibleName ||
                  !TextoTarifaGrupo.toUpperCase().includes("FLEXIBLE"))
              );
            });

            this.contrato.detallesContratacion.producto =
              this.selects.contratacion.producto.find(
                (p) => p.TextoTarifaGrupo == this.flexibleName
              ) || null;

            if (
              this.flexibleName &&
              this.contrato.detallesContratacion.producto == null
            ) {
              this.selects.contratacion.producto.push({
                TextoTarifaGrupo: this.flexibleName,
              });
              this.contrato.detallesContratacion.producto =
                this.selects.contratacion.producto.find(
                  (p) => p.TextoTarifaGrupo == this.flexibleName
                ) || null;
            }

            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    getPerfilFacturacionContratacion() {
      return new Promise((resolve, reject) => {
        axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}/PeriodoFactura/GetDTOAllPeriodos/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}/Linea/1`,
          headers: {
            Authorization: this.$store.getters.getToken,
          },
        })
          .then((res) => {
            this.selects.contratacion.perfilFacturacion = processToken(
              res.data
            ).iss;

            this.contrato.detallesContratacion.perfilFacturacion =
              this.selects.contratacion.perfilFacturacion.find(
                (p) => p.TextoPerfilFacturacion == "Alumbra BT Variable ML"
              ) || this.selects.contratacion.perfilFacturacion[0];

            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    getTipoCobroBancarios() {
      return new Promise((resolve, reject) => {
        axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}/TipoCobro/GetDTOAll/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}`,
          headers: {
            Authorization: this.$store.getters.getToken,
          },
        })
          .then((res) => {
            this.selects.bancarios.tipoCobro = processToken(
              res.data
            ).iss.filter((tipo) => tipo.TextoTipoCobro == "DOMICILIADO");
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    getCnaeBancarios() {
      return new Promise((resolve, reject) => {
        axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}/Empresa/GetAllCNAE/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}`,
          headers: {
            Authorization: this.$store.getters.getToken,
          },
        })
          .then((res) => {
            this.selects.bancarios.cnae = processToken(res.data).iss.map(
              (item) => {
                item.texto = [item.CodigoCNAE, item.TextoCNAE]
                  .filter((i) => i != null)
                  .join(" - ");
                return item;
              }
            );
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    getPeriodoLecturaOtros() {
      return new Promise((resolve, reject) => {
        axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}/PeriodoFactura/GetDTOAll/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}`,
          headers: {
            Authorization: this.$store.getters.getToken,
          },
        })
          .then((res) => {
            this.selects.otros.periodoLectura = processToken(
              res.data
            ).iss.filter((per) => per.TextoPeriodo == "MENSUAL");
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    getTipoSolicitudOtros() {
      return new Promise((resolve, reject) => {
        axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}/SolicitudTipo/GetDTOAll/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}`,
          headers: {
            Authorization: this.$store.getters.getToken,
          },
        })
          .then((res) => {
            this.selects.otros.tipoSolicitud = processToken(
              res.data
            ).iss.filter((item) => item.SelecionableDesdeContrato == true);
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    getModeloContratoOtros() {
      return new Promise((resolve, reject) => {
        axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}/Contrato/GetDTOAllModelosContrato/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}/Agente/${this.idAgente}`,
          headers: {
            Authorization: this.$store.getters.getToken,
          },
        })
          .then((res) => {
            this.selects.otros.modeloContrato = processToken(
              res.data
            ).iss.filter(
              (modelo) =>
                modelo.DescripcionModeloDeImpresion != "Contrato General" &&
                modelo.DescripcionModeloDeImpresion.toLowerCase().includes(
                  this.$store.getters.getNombreEmpresa.toLowerCase() ==
                    "alumbraenergia"
                    ? "alumbra"
                    : this.$store.getters.getNombreEmpresa.toLowerCase()
                )
            );
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    getIdiomasContratoOtros() {
      return new Promise((resolve, reject) => {
        axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}/Contrato/GetDTOAllIdiomasContrato/Empresa/${this.idEmpresa}/Usuario/${this.idUsuario}/Agente/${this.idAgente}`,
          headers: {
            Authorization: this.$store.getters.getToken,
          },
        })
          .then((res) => {
            this.selects.otros.idiomas = processToken(res.data).iss;
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    getFechaEfectoOtros() {
      return new Promise((resolve, reject) => {
        axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}/FechaEfecto/GetDTOAll/Empresa/1/Usuario/1`,
          headers: {
            Authorization: this.$store.getters.getToken,
          },
        })
          .then((res) => {
            let temp = processToken(res.data).iss;
            for (const option of temp) {
              option.TextoFechaEfecto = option.TextoFechaEfecto.replace(
                / *\(2017\)/g,
                ""
              );
            }
            this.selects.otros.fechaEfecto = temp;
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    getColectivoRepresentante() {
      return new Promise((resolve, reject) => {
        axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URL}/Contrato/GetAllColectivos`,
          headers: {
            Authorization: this.$store.getters.getToken,
          },
          data: { IdEmpresa: this.idEmpresa, IdUsuario: this.idUsuario },
        })
          .then((res) => {
            this.selects.representante.colectivos = processToken(res.data).iss;
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    getDatosFlexible() {
      return new Promise((resolve, reject) => {
        if (!this.contrato.detallesContratacion.producto.TextoTarifaGrupo.includes('FLEXIBLE')) return resolve()
        axios({
          method: "get",
          url: `${process.env.VUE_APP_OUR_API_URL}/altaproducto.php`,
          params: {
            productoFlexible: this.contrato.detallesContratacion.producto
              .TextoTarifaGrupo,
            token: this.$store.getters.getJwtEmpresa,
          },
        })
          .then((res) => {
            for (let [key, value] of Object.entries(res.data)) {
              // console.log({key, value})
              if (key.includes("feep")) {
                value = Math.round(value * 2) / 2;
              }
              this.$set(this.datosFlexible, key, Number(value));
            }
            // this.datosFlexible = res.data;
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    getCapacidad() {
      return new Promise((resolve, reject) => {
        axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URL}/Contrato/GetAllTiposCapacidad`,
          headers: {
            Authorization: this.$store.getters.getToken,
          },
          data: { IdEmpresa: this.idEmpresa, IdUsuario: this.idUsuario },
        })
          .then((res) => {
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    getDocumentacion() {
      if (!this.$route.query.id) return;
      return new Promise((resolve, reject) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_OUR_API_URL}/subir_documentacion.php`,
          params: {
            idBorrador: this.$route.query.id,
            token: this.$store.getters.getJwtEmpresa,
          },
        })
          .then(({ data }) => {
            this.documentos = Object.values(data).map((f) => ({ name: f }));
            resolve();
          })
          .catch((e) => {
            // console.trace(e);
            this.$root.$emit(
              "snack",
              "No se ha podido encontrar la documentación"
            );
            reject();
          });
      });
    },
    eliminarBorradores(estado, id) {
      if (
        !confirm(
          `¿Estás seguro de que quieres cambiar el estado a ${estado ||
            "eliminado"}?`
        )
      )
        return;
      axios({
        method: "POST",
        url: `${process.env.VUE_APP_OUR_API_URL}/eliminar_borradores.php`,
        data: {
          token: this.$store.getters.getJwtEmpresa,
          borradores: [id],
          estado,
        },
      }).then((res) => this.$router.push({ name: "Borradores" }));
    },
    async cargarContrato(validate = false, del = false) {
      this.$nextTick(() => {
        if (!validate) this.cargandoContrato = true;
      });

      return new Promise(async (resolve, reject) => {
        try {
          let cambios = this.hasChanged;
          if (validate) {
            let prev = this.esBorrador;
            this.esBorrador = false;
            this.errors = !this.$refs["formularioContrato"].validate();
            this.esBorrador = prev;
            resolve();
            return;
          }

          if (this.esProductoFlexible)
            this.$set(this.checks, "productoFlexible", {
              progress: false,
              texto: "Creando producto flexible",
            });
          this.$set(this.checks, "guardarBorrador", {
            progress: false,
            texto: "Guardando borrador",
          });
          this.$set(this.checks, "comprobandoCampos", {
            progress: false,
            texto: "Comprobando campos",
          });
          this.$set(this.checks, "verificarCliente", {
            progress: false,
            texto: "Verificar los datos del cliente",
          });
          if (this.$route.query.id) {
            this.$set(this.checks, "verificar", {
              progress: false,
              texto: "Verificando los datos del contrato",
            });
            this.$set(this.checks, "subir", {
              progress: false,
              texto: "Subiendo el contrato a verificación",
            });
            if (this.contrato.enviarSMS) {
              this.$set(this.checks, "firma", {
                progress: false,
                texto: "Enviando SMS de firma",
              });
            } else if (this.contrato.enviarEmail) {
              this.$set(this.checks, "firma", {
                progress: false,
                texto: "Enviando email de firma",
              });
            }
            for (let i = 0; i < this.documentos.length; i++) {
              this.$set(this.checks, `documentacion${i}`, {
                progress: false,
                texto: `Subiendo ${this.documentos[i].name}`,
              });
            }
          }

          let auxFecha = new Date();
          let fechaAlta = new Date(
            `${auxFecha.getFullYear()}-${(
              "0" +
              (auxFecha.getMonth() + 1)
            ).slice(-2)}-${("0" + auxFecha.getDate()).slice(-2)}`
          );
          if (fechaAlta.getTime() < 10) fechaAlta = new Date();
          try {
            !this.contrato.otrosDatos.fechaEfecto.FechaRequerida
              ? fechaAlta.setDate(fechaAlta.getDate() + 4)
              : null;
          } catch {}
          let fechaVto = new Date(fechaAlta);
          fechaVto.setFullYear(fechaVto.getFullYear() + 1);

          // "2021/07/25"

          let ciudadBase = {
            desPais: "",
            codPais: "",
            desCiudad: "",
            codMunicipio: "",
            desCiudad: "",
            codCiudad: "",
            desProvincia: "",
            codProvincia: "",
            codigoProveedorProvincia: "",
            codigoProveedorMunicipio: "",
            codigoProveedorCiudad: "",
          };

          let calleBase = {
            tipoCalle: "",
          };

          if (this.contrato.puntoSuministro.ciudad == null)
            this.contrato.puntoSuministro.ciudad = ciudadBase;
          if (this.contrato.datosTitular.ciudad == null)
            this.contrato.datosTitular.ciudad = ciudadBase;
          if (this.contrato.datosEnvio.ciudad == null)
            this.contrato.datosEnvio.ciudad = ciudadBase;

          if (this.contrato.puntoSuministro.calle == null)
            this.contrato.puntoSuministro.calle = calleBase;
          if (this.contrato.datosTitular.calle == null)
            this.contrato.datosTitular.calle = calleBase;
          if (this.contrato.datosEnvio.calle == null)
            this.contrato.datosEnvio.calle = calleBase;

          // if (this.contrato.detallesContratacion.tarifa == null)
          // 	this.contrato.detallesContratacion.tarifa = { TextoTarifa: "" };

          if (this.contrato.datosBancarios.tipoCobro == null)
            this.contrato.datosBancarios.tipoCobro = { TextoTipoCobro: "" };

          // if (this.contrato.detallesContratacion.producto == null)
          // 	this.contrato.detallesContratacion.producto = { TextoTarifaGrupo: "" };

          if (this.contrato.detallesContratacion.perfilFacturacion == null)
            this.contrato.detallesContratacion.perfilFacturacion = {
              TextoPerfilFacturacion: "",
            };

          if (this.contrato.otrosDatos.idiomaContrato == null)
            this.contrato.otrosDatos.idiomaContrato = { IdIdiomaInforme: "" };

          if (this.contrato.otrosDatos.periodoLectura == null)
            this.contrato.otrosDatos.periodoLectura = { TextoPeriodo: "" };

          if (this.contrato.otrosDatos.tipoImpresionFactura == null)
            this.contrato.otrosDatos.tipoImpresionFactura = { Valor: "" };

          if (this.contrato.otrosDatos.tipoSolicitud == null)
            this.contrato.otrosDatos.tipoSolicitud = { OperacionAsociada: "" };

          if (this.contrato.otrosDatos.modeloContrato == null)
            this.contrato.otrosDatos.modeloContrato = {
              IdModeloDeImpresion: "",
              DescripcionModeloDeImpresion: "",
            };

          if (this.contrato.otrosDatos.fechaEfecto == null)
            this.contrato.otrosDatos.fechaEfecto = {
              IdSolicitudTipoFechaEfecto: "",
            };

          let nombreProducto;
          if (this.esProductoFlexible) {
            this.checks.productoFlexible.progress = "loading";
            try {
              nombreProducto = await this.crearProductoFlexible();
              this.checks.productoFlexible.progress = true;
            } catch (e) {
              this.$root.$emit(
                "snack",
                "No se ha podido crear el producto flexible"
              );
              this.checks.productoFlexible.progress = "error";
              throw e;
            }
          } else {
            if (this.contrato.detallesContratacion.tarifa == null)
              this.contrato.detallesContratacion.tarifa = { TextoTarifa: "" };
            if (this.contrato.detallesContratacion.producto == null)
              this.contrato.detallesContratacion.producto = {
                TextoTarifaGrupo: "",
              };
            nombreProducto = this.contrato.detallesContratacion.producto
              .TextoTarifaGrupo;
          }

          let IdCalendario = null;
          if (this.$store.getters.getEntorno == "Luz") {
            IdCalendario =
              this.contrato.puntoSuministro.ciudad.codigoProveedorProvincia ==
              "Ine-1-7"
                ? 4
                : 1;
          } else if (this.$store.getters.getEntorno == "Gas") {
            IdCalendario = 5;
          }

          let contratoPotencial = {
            idBorrador: this.$route.query.id,
            idUsuarioBorrador:
              this.contrato.idUsuarioBorrador ||
              JSON.parse(
                decode64(this.$store.getters.getJwtEmpresa.split(".")[1])
              ).idUsuario,
            IdEmpresa: this.idEmpresa,
            IdUsuario: Number(this.idUsuario),
            entorno: this.$store.getters.getEntorno,

            enviarSMS: this.contrato.enviarSMS || false,
            enviarEmail: this.contrato.enviarEmail || false,
            estado: this.contrato.estado || "Borrador",
            empresa:
              this.contrato.empresa ||
              JSON.parse(
                decode64(this.$store.getters.getJwtEmpresa.split(".")[1])
              ).empresa,
            residencial: this.contrato.residencial,

            ContratosPotenciales: [
              {
                ActivacionPrevista: this.contrato.otrosDatos.activacionPrevista
                  ? this.contrato.otrosDatos.activacionPrevista.replaceAll(
                      /\-/g,
                      "/"
                    )
                  : null,
                CodigoCUPS: this.contrato.puntoSuministro.cups.toUpperCase(),
                CodigoREEDistribuidora: this.contrato.puntoSuministro
                  .codDistribuidora,
                CallePuntoSuministro:
                  (typeof this.contrato.puntoSuministro.calle != "string" &&
                  this.contrato.puntoSuministro.calle != null
                    ? this.contrato.puntoSuministro.calle.desCalle
                    : this.contrato.puntoSuministro.calle) || "",
                Numero: this.contrato.puntoSuministro.numero,
                Aclarador: this.contrato.puntoSuministro.aclarador,
                CodPostal: this.contrato.puntoSuministro.codPostal,
                PaisSuministro: this.contrato.puntoSuministro.ciudad.desPais,
                InePaisSuministro: this.contrato.puntoSuministro.ciudad.codPais,
                MunicipioSuministro: this.contrato.puntoSuministro.ciudad
                  .desCiudad,
                IneMunicipioSuministro: this.contrato.puntoSuministro.ciudad
                  .codMunicipio,
                CiudadSuministro: this.contrato.puntoSuministro.ciudad
                  .desCiudad,
                IneCiudadSuministro: this.contrato.puntoSuministro.ciudad
                  .codCiudad,
                ProvinciaSuministro: (
                  this.contrato.puntoSuministro.ciudad.desProvincia || ""
                ).toUpperCase(),
                IneProvinciaSuministro: (
                  "00" + this.contrato.puntoSuministro.ciudad.codProvincia
                ).slice(-2),
                CodigoProveedorProvinciaSuministro: this.contrato
                  .puntoSuministro.ciudad.codigoProveedorProvincia,
                CodigoProveedorMunicipioSuministro: this.contrato
                  .puntoSuministro.ciudad.codigoProveedorMunicipio,
                CodigoProveedorCiudadSuministro: this.contrato.puntoSuministro
                  .ciudad.codigoProveedorCiudad,
                ComercializadoraREE: "", //?
                TextoTarifaAcceso: this.contrato.detallesContratacion.tarifa.TextoTarifa.replaceAll(
                  / ML/g,
                  ""
                ),
                PerfilFacturacionPeaje: "", //?
                P1: this.contrato.detallesContratacion.p1,
                P2: this.contrato.detallesContratacion.p2,
                P3: this.contrato.detallesContratacion.p3,
                P4: this.contrato.detallesContratacion.p4,
                P5: this.contrato.detallesContratacion.p5,
                P6: this.contrato.detallesContratacion.p6,
                ConsumoEstimadoP1: this.contrato.detallesContratacion
                  .ConsumoEstimadoP1,
                ConsumoEstimadoP2: this.contrato.detallesContratacion
                  .ConsumoEstimadoP2,
                ConsumoEstimadoP3: this.contrato.detallesContratacion
                  .ConsumoEstimadoP3,
                ConsumoEstimadoP4: this.contrato.detallesContratacion
                  .ConsumoEstimadoP4,
                ConsumoEstimadoP5: this.contrato.detallesContratacion
                  .ConsumoEstimadoP5,
                ConsumoEstimadoP6: this.contrato.detallesContratacion
                  .ConsumoEstimadoP6,
                Identificador: this.contrato.datosTitular.identificador,
                NombreTitular: this.contrato.datosTitular.nombre,
                Apellido1Titular: this.contrato.datosTitular.apellido1,
                Apellido2Titular: this.contrato.datosTitular.apellido2,
                RazonSocialTitular: this.contrato.datosTitular.razonSocial,
                CPTitular: this.contrato.datosTitular.codPostal,
                PaisTitular: this.contrato.datosTitular.ciudad.desPais,
                InePaisTitular: this.contrato.datosTitular.ciudad.codPais,
                MunicipioTitular: this.contrato.datosTitular.ciudad.desCiudad,
                IneMunicipioTitular: this.contrato.datosTitular.ciudad
                  .codMunicipio,
                CiudadTitular: this.contrato.datosTitular.ciudad.desCiudad,
                IneCiudadTitular: this.contrato.datosTitular.ciudad.codCiudad,
                CalleTitular:
                  (typeof this.contrato.datosTitular.calle != "string" &&
                  this.contrato.datosTitular.calle != null
                    ? this.contrato.datosTitular.calle.desCalle
                    : this.contrato.datosTitular.calle) || "",
                NumeroTitular: this.contrato.datosTitular.numero,
                AclaradorTitular: this.contrato.datosTitular.aclarador,
                ProvinciaTitular: (
                  this.contrato.datosTitular.ciudad.desProvincia || ""
                ).toUpperCase(),
                IneProvinciaTitular: (
                  "00" + this.contrato.datosTitular.ciudad.codProvincia
                ).slice(-2),
                CodigoProveedorProvinciaTitular: this.contrato.datosTitular
                  .ciudad.codigoProveedorProvincia,
                CodigoProveedorMunicipioTitular: this.contrato.datosTitular
                  .ciudad.codigoProveedorMunicipio,
                CodigoProveedorCiudadTitular: this.contrato.datosTitular.ciudad
                  .codigoProveedorCiudad,
                TfnoTitular: this.contrato.datosTitular.telefonoFijo,
                Movil: this.contrato.datosTitular.telefonoMovil,
                EmailTitular: this.contrato.datosTitular.email,
                IsDestinoEnergia: false, //? DEPENDE DE QUE UN CAMPO LLAMADO DOMESTICO SEA 'true', SI NO SIEMPRE FALSE
                NombreEnvio: this.contrato.datosEnvio.nombre,
                PaisEnvio: this.contrato.datosEnvio.ciudad.desPais,
                InePaisEnvio: this.contrato.datosEnvio.ciudad.codPais,
                MunicipioEnvio: this.contrato.datosEnvio.ciudad.desCiudad,
                IneMunicipioEnvio: this.contrato.datosEnvio.ciudad.codMunicipio,
                CPEnvio: this.contrato.datosEnvio.codPostal,
                CiudadEnvio: this.contrato.datosEnvio.ciudad.desCiudad,
                IneCiudadEnvio: this.contrato.datosEnvio.ciudad.codCiudad,
                CalleEnvio:
                  (typeof this.contrato.datosEnvio.calle != "string" &&
                  this.contrato.datosEnvio.calle != null
                    ? this.contrato.datosEnvio.calle.desCalle
                    : this.contrato.datosEnvio.calle) || "",
                NumeroEnvio: this.contrato.datosEnvio.numero,
                AclaradorEnvio: this.contrato.datosEnvio.aclarador,
                ProvinciaEnvio: (
                  this.contrato.datosEnvio.ciudad.desProvincia || ""
                ).toUpperCase(),
                IneProvinciaEnvio: (
                  "00" + this.contrato.datosEnvio.ciudad.codProvincia
                ).slice(-2),
                CodigoProveedorProvinciaEnvio: this.contrato.datosEnvio.ciudad
                  .codigoProveedorProvincia,
                CodigoProveedorMunicipioEnvio: this.contrato.datosEnvio.ciudad
                  .codigoProveedorMunicipio,
                CodigoProveedorCiudadEnvio: this.contrato.datosEnvio.ciudad
                  .codigoProveedorCiudad,
                NombrePagador: this.contrato.datosBancarios.nombrePagador,
                IdentificadorPagador: this.contrato.datosBancarios
                  .identificador,
                TextoTipoCobro: this.contrato.datosBancarios.tipoCobro
                  .TextoTipoCobro,
                IBAN: this.contrato.datosBancarios.iban,
                BIC: this.contrato.datosBancarios.bic,
                Tarifa: this.contrato.detallesContratacion.tarifa.TextoTarifa,
                TarifaGrupo: nombreProducto,
                PerfilFacturacionContratoTarifa: this.contrato
                  .detallesContratacion.perfilFacturacion
                  .TextoPerfilFacturacion,
                FechaContrato: new Date()
                  .toJSON()
                  .slice(0, 10)
                  .replaceAll(/-/g, "/"),
                IdIdiomaInforme: this.contrato.otrosDatos.idiomaContrato
                  .IdIdiomaInforme,
                TextoPeriodo: this.contrato.otrosDatos.periodoLectura
                  .TextoPeriodo,
                TextoTipoImpresion: this.contrato.otrosDatos
                  .tipoImpresionFactura.Valor,
                TipoSolicitud: this.contrato.otrosDatos.tipoSolicitud
                  .OperacionAsociada,
                RefCatastral: "", //? SIEMPRE VACIO
                RefExterna: "", //? SIEMPRE VACIO
                CodigoCNAE: this.contrato.datosBancarios.cnae
                  ? this.contrato.datosBancarios.cnae.CodigoCNAE
                  : "",
                Producto: this.contrato.serviciosValor.producto,
                NombreAgente: this.NombreAgente,
                CodigoVendedor: this.contrato.otrosDatos.codigoVendedor || "", //? DE MOMENTO NO SE PUEDE SELECCIONAR NINGUNO
                IdModeloContrato: this.contrato.otrosDatos.modeloContrato
                  .IdModeloDeImpresion,
                TextoModeloContrato: this.contrato.otrosDatos.modeloContrato
                  .DescripcionModeloDeImpresion,
                Observaciones: this.contrato.observaciones,
                IdContratoSituacion: "", //? VACÍO POR DEFECTO
                IdClienteCategoria: "", //? PARECE QUE NO SE PUEDE SELECCIONAR EN NINGÚN SITIO
                ReferenciaExterna: "", //? PARECE QUE NO SE PUEDE SELECCIONAR EN NINGÚN SITIO
                Segmentacion: "", //? TIENEN LOS INPUTS COMENTADOS EN SU CODIGO
                FechaEfecto: this.contrato.otrosDatos.fechaEfecto
                  .IdSolicitudTipoFechaEfecto,
                ComisionCalculada: "", //? PARECE QUE NO SE PUEDE SELECCIONAR EN NINGÚN SITIO
                ConsumoEstimado: Math.round(
                  this.contrato.detallesContratacion.consumoAnualSips
                ),
                Fee: "", //? PARECE QUE NO SE PUEDE SELECCIONAR EN NINGÚN SITIO
                PorcentajeCO: "", //? PARECE QUE NO SE PUEDE SELECCIONAR EN NINGÚN SITIO
                FechaAlta: new Date(fechaAlta)
                  .toJSON()
                  .slice(0, 10)
                  .replaceAll(/-/g, "/"), //? EL DIA EN EL QUE SE SUBE + 4 O LA FECHA SELECCIONADA, PARECE QUE SI PONES PROXIMO CICLO DE LECTURA LO IGNORA
                FechaVto: new Date(fechaVto)
                  .toJSON()
                  .slice(0, 10)
                  .replaceAll(/-/g, "/"), //? LA FECHA DE ALTA + 1 AÑO
                CosteGestion: "", //? PARECE QUE NO SE PUEDE SELECCIONAR EN NINGÚN SITIO
                TextoViaSuministro:
                  this.contrato.puntoSuministro.calle.tipoCalle || "CALLE",
                TextoViaTitular:
                  this.contrato.datosTitular.calle.tipoCalle || "CALLE",
                TextoViaEnvio:
                  this.contrato.datosEnvio.calle.tipoCalle || "CALLE",

                Representante: this.contrato.datosRepresentante
                  .nombreRepresentante,
                IdentificadorRep: this.contrato.datosRepresentante
                  .identificadorRepresentante,
                IdColectivoRep:
                  this.contrato.datosRepresentante.colectivo != null
                    ? this.contrato.datosRepresentante.colectivo.IdColectivo
                    : "",
                Comentario: this.contrato.datosRepresentante.comentario,

                IdDestinoEnergia: "", //? DEPENDE DE SEGMENTACION Y ESO DE MOMENTO NO SE PUEDE PONER
                IdTipoCapacidad: "", //? SOLO GAS
                Capacidad: "", //? SOLO GAS
                FechaCapDesde: "", //? SOLO GAS, FECHA DE HOY
                FechaCapHasta: "", //? SOLO GAS, FECHA DE HOY + 1 AÑO
                IdTipoLectura: "", //? SOLO GAS
                PCTDGas: "", //? SOLO GAS
                IdTarifaAnual:
                  this.contrato.detallesContratacion.tarifa.IdTarifa || "",
                CodigoPromocional: "", //? DE MOMENTO NO TENEMOS ESO
                ComentarioPiePagina: "", //? EL INPUT NO ESTÁ O ESTÁ DESACTIVADO
                BloqueoSistemaComercial: false, //?FALTA EL CHECKBOX O ESTÁ DESACTIVADO
                BloqueoPublicidadEmpresa: false, //?FALTA EL CHECKBOX O ESTÁ DESACTIVADO
                BloqueoUsoTercero: false, //?FALTA EL CHECKBOX O ESTÁ DESACTIVADO
                AgenteFEE: 0, //? DEPENDE DE FEE Y OTROS CAMPOS QUE NO ESTÁ ACTIVOS
                PFEE1: 0, //? DEPENDE DE FEE Y OTROS CAMPOS QUE NO ESTÁ ACTIVOS
                PFEE2: 0, //? DEPENDE DE FEE Y OTROS CAMPOS QUE NO ESTÁ ACTIVOS
                PFEE3: 0, //? DEPENDE DE FEE Y OTROS CAMPOS QUE NO ESTÁ ACTIVOS
                PFEE4: 0, //? DEPENDE DE FEE Y OTROS CAMPOS QUE NO ESTÁ ACTIVOS
                PFEE5: 0, //? DEPENDE DE FEE Y OTROS CAMPOS QUE NO ESTÁ ACTIVOS
                PFEE6: 0, //? DEPENDE DE FEE Y OTROS CAMPOS QUE NO ESTÁ ACTIVOS
                CFEE1: 0, //? DEPENDE DE FEE Y OTROS CAMPOS QUE NO ESTÁ ACTIVOS
                CFEE2: 0, //? DEPENDE DE FEE Y OTROS CAMPOS QUE NO ESTÁ ACTIVOS
                CFEE3: 0, //? DEPENDE DE FEE Y OTROS CAMPOS QUE NO ESTÁ ACTIVOS
                CFEE4: 0, //? DEPENDE DE FEE Y OTROS CAMPOS QUE NO ESTÁ ACTIVOS
                CFEE5: 0, //? DEPENDE DE FEE Y OTROS CAMPOS QUE NO ESTÁ ACTIVOS
                CFEE6: 0, //? DEPENDE DE FEE Y OTROS CAMPOS QUE NO ESTÁ ACTIVOS
                IdMarca: "", //? SOLO GAS
                PorcentajeComision: 0, //? DEPENDE DE INPUTS DESACTIVADOS
                Booleanos: {
                  IsFirmaDigital: false, //this.contrato.certificadoElectronico,
                  IsElectronico: false, //? FALSE POR DEFECTO
                },
                EmailRep: this.contrato.datosRepresentante.emailRepresentante,
                SMSRep: this.contrato.datosRepresentante.telefonoRepresentante,
                duracionContrato: this.contrato.otrosDatos.duracionContrato,

                IdUP:
                  this.contrato.puntoSuministro.ciudad
                    .codigoProveedorProvincia == "Ine-1-7"
                    ? 4
                    : 3,
                IdCalendario: IdCalendario,
              },
            ],
          };

          this.hasChanged = cambios;

          // if (this.esBorrador) {
          try {
            if (this.checks.guardarBorrador)
              this.checks.guardarBorrador.progress = "loading";
            let { data: resultado } = await axios({
              method: "POST",
              url: `${process.env.VUE_APP_OUR_API_URL}/contratos.php`,
              data: {
                ...contratoPotencial,
                estado: contratoPotencial.estado,
                token: this.$store.getters.getJwtEmpresa,
              },
            });

            if (resultado) {
              if (this.checks.eliminando)
                this.checks.eliminando.progress = true;
              if (this.checks.guardarBorrador)
                this.checks.guardarBorrador.progress = true;

              // this.$root.$emit("snack", "Borrador guardado con éxito");
              resolve(true);
              if (this.unsavedChanges.show) {
                return;
              }
              if (this.esBorrador) {
                if (this.$route.query.id) {
                  this.hasChanged = false;
                  this.$router.push({
                    name: "Borradores",
                  });
                } else
                  this.$router.push({
                    name: "CargarContrato",
                    query: { id: resultado },
                  });
                return;
              }
            } else {
              if (this.checks.eliminando)
                this.checks.eliminando.progress = "error";
              this.$root.$emit("snack", "Ha ocurrido un error");
            }
          } catch (e) {
            console.log(e);
            if (this.contrato.puntoSuministro.calle == calleBase)
              this.contrato.puntoSuministro.calle = null;
            if (this.contrato.datosTitular.calle == calleBase)
              this.contrato.datosTitular.calle = null;
            if (this.contrato.datosEnvio.calle == calleBase)
              this.contrato.datosEnvio.calle = null;
            this.cargandoContrato = false;
          }
          if (!this.$refs.formularioContrato.validate()) {
            this.checks.comprobandoCampos.progress = "error";
            throw "Campos faltantes o erróneos";
          } else {
            this.checks.comprobandoCampos.progress = true;
          }
          // }
          this.incidencias = [];
          // if (
          // 	!confirm(
          // 		"¿Estás seguro de que quieres enviar este contrato a verificación?"
          // 	)
          // ) {
          // 	this.cargandoContrato = false;
          // 	return;
          // }
          if (this.checks.verificarCliente)
            this.checks.verificarCliente.progress = "loading";

          let datosCliente = await this.buscarCliente(true);

          let verificarCliente = {
            IdCliente: datosCliente.IdCliente,
            TipoNumero: datosCliente.TipoNumero,
            Aclarador: this.contrato.datosTitular.aclarador || "",
            Apellido1: this.contrato.datosTitular.apellido1 || "",
            Apellido2: this.contrato.datosTitular.apellido2 || "",
            Calle:
              this.contrato.datosTitular.calle.desCalle ||
              this.contrato.datosTitular.calle ||
              "",
            Ciudad:
              this.contrato.datosTitular.ciudad.desCiudad ||
              this.contrato.datosTitular.ciudad ||
              "",
            ClientesContacto: [
              this.contrato.datosTitular.telefonoMovil != null
                ? {
                    TipoContacto: "M",
                    Valor: this.contrato.datosTitular.telefonoMovil,
                  }
                : null,
              this.contrato.datosTitular.telefonoFijo != null
                ? {
                    TipoContacto: "F",
                    Valor: this.contrato.datosTitular.telefonoFijo,
                  }
                : null,
              this.contrato.datosTitular.email != null
                ? { TipoContacto: "E", Valor: this.contrato.datosTitular.email }
                : null,
            ].filter((n) => !!n),
            CodPostal: this.contrato.datosTitular.codPostal || "",
            Identificador: this.contrato.datosTitular.identificador || "",
            Nombre: this.contrato.datosTitular.nombre || "",
            Numero: this.contrato.datosTitular.numero || "",
            Provincia: this.contrato.datosTitular.provincia || "",
            RazonSocial: this.contrato.datosTitular.razonSocial || "",
          };

          let errores = [];

          const comparar = (a, b) => {
            Object.keys(a).forEach((key) => {
              if (a[key] == null) a[key] = "";
              if (b[key] == null) b[key] = "";

              console.log({ KEY: key, SIGE: a[key], impagados: b[key] });

              if (Array.isArray(b[key])) {
                const map1 = a[key]
                  .map((item) => item.Valor)
                  .filter((i) => !!i);
                const map2 = b[key]
                  .map((item) => item.Valor)
                  .filter((i) => !!i);
                if (!map2.every((elem) => map1.includes(elem))) {
                  errores.push("Contactos");
                }
              } else if (
                !(!a[key] && !b[key]) &&
                String(a[key]).toLowerCase() != String(b[key]).toLowerCase()
              ) {
                errores.push(key);
              }
            });
          };

          if (datosCliente.IdCliente != 0) {
            comparar(datosCliente, verificarCliente);
          }

          if (errores.length > 0) {
            if (this.checks.verificarCliente)
              this.checks.verificarCliente.progress = "error";

            this.incidencias.push({
              Mensaje: `Los datos del titular introducidos no coinciden con nuestra base de datos, revisalos`,
              info: errores,
            });
            this.cargandoContrato = false;
            reject();
            return;
          } else {
            if (this.checks.verificarCliente)
              this.checks.verificarCliente.progress = true;
          }

          if (!this.$route.query.id) {
            this.cargandoContrato = false;
            reject();
            return;
          }

          if (!this.$refs["formularioContrato"].validate()) {
            this.checks.verificar.progress = "error";
            this.cargandoContrato = false;
            reject();
            return;
          }

          this.checks.verificar.progress = true;

          try {
            var res = await axios({
              method: "POST",
              url: `${process.env.VUE_APP_API_URL}/ContratoPotencial/Verificar/`,
              data: contratoPotencial,
              headers: {
                Authorization: this.$store.getters.getToken,
              },
            });
            this.incidencias = res.data.Contratos[0].Incidencias;
            if (this.incidencias.length > 0) {
              this.checks.verificar.progress = "error";
              throw this.incidencias;
            } else {
              this.checks.verificar.progress = true;
            }
          } catch (e) {
            this.checks.verificar.progress = "error";
            this.cargandoContrato = false;
            reject();
            return;
          }

          this.checks.subir.progress = "loading";

          try {
            var { data } = await axios({
              method: "POST",
              url: `${process.env.VUE_APP_API_URL}/ContratoPotencial/POST/`,
              data: contratoPotencial,
              headers: {
                Authorization: this.$store.getters.getToken,
              },
            });
            if (this.$store.getters.getEntorno == "Gas")
              data.Contratos[0].CodigoContrato *= -1;
            if (data.Contratos[0].Incidencias.length > 0) {
              this.checks.subir.progress = "error";
              throw data.Contratos[0].Incidencias;
            } else {
              this.checks.subir.progress = true;
            }
          } catch (e) {
            this.checks.subir.progress = "error";
            // this.$root.$emit(
            // 	"snack",
            // 	"No se ha podido subir el contrato a verificación"
            // );
            this.cargandoContrato = false;
            this.incidencias = data.Contratos[0].Incidencias;
            reject();
            return;
          }

          if (
            this.esProductoFlexible ||
            nombreProducto.toUpperCase().includes("FLEXIBLE")
          ) {
            try {
              // const { IdAgente } = this.$store.getters.getDatosEmpresa.iss;
              var { data: creacionComision } = await axios({
                url: `${process.env.VUE_APP_OUR_API_URL}/altaproducto.php`,
                method: "PUT",
                data: {
                  CodigoContrato: data.Contratos[0].CodigoContrato,
                  token: this.$store.getters.getJwtEmpresa,
                  IdAgente: this.idAgente,
                },
                headers: {
                  Authorization: this.$store.getters.getToken,
                },
              });
              console.log(creacionComision);
            } catch (e) {
              console.error(e);
              // this.$root.$emit(
              // 	"snack",
              // 	"No se ha podido subir el contrato a verificación"
              // );
              this.cargandoContrato = false;
              reject();
              return;
            }
          }

          if (this.checks.firma) this.checks.firma.progress = "loading";

          let estado = "Enviado a verificación";

          if (
            this.contrato.datosTitular.telefonoMovil &&
            this.contrato.enviarSMS
          ) {
            try {
              await axios({
                method: "GET",
                url: `${process.env.VUE_APP_OUR_API_URL}/enviar_firma_sms.php`,
                params: {
                  codigoContrato: data.Contratos[0].CodigoContrato,
                  empresa: this.$store.getters.getNombreEmpresa,
                  idContrato: data.Contratos[0].CodigoContrato,
                  token: this.$store.getters.getJwtEmpresa,
                  IdAgente: this.idAgente,
                },
              });
              this.checks.firma.progress = true;
              // this.$root.$emit("snack", "Se ha enviado el SMS de verificación");
            } catch {
              this.checks.firma.progress = "error";
              // this.$root.$emit(
              // 	"snack",
              // 	"No se ha podido enviar el SMS de verificación"
              // );
              this.cargandoContrato = false;
              reject();
              return;
            }
          } else if (
            this.contrato.datosTitular.email &&
            this.contrato.enviarEmail
          ) {
            try {
              await axios({
                method: "GET",
                url: `${process.env.VUE_APP_OUR_API_URL}/enviar_firma_email.php`,
                params: {
                  codigoContrato: data.Contratos[0].CodigoContrato,
                  empresa: this.$store.getters.getNombreEmpresa,
                  idContrato: data.Contratos[0].CodigoContrato,
                  token: this.$store.getters.getJwtEmpresa,
                  IdAgente: this.idAgente,
                },
              });
              this.checks.firma.progress = true;
              // this.$root.$emit("snack", "Se ha enviado el email de firma");
            } catch {
              this.checks.firma.progress = "error";
              // this.$root.$emit(
              // 	"snack",
              // 	"No se ha podido enviar el email de firma"
              // );
              this.cargandoContrato = false;
              reject();
              return;
            }
          }

          // if (this.documentos.length > 0) {
          // 	this.checks.documentacion = {
          // 		max: this.documentos.length,
          // 		value: 0,
          // 		texto: "Subiendo documentacion",
          // 	};
          // }

          const timer = (ms) => new Promise((res) => setTimeout(res, ms));

          const upload = (doc) => {
            return new Promise(async (resolve, reject) => {
              try {
                await this.cargarDocumento(data.Contratos[0], doc.name);
                resolve();
              } catch (e) {
                reject();
              }
            });
          };

          try {
            for (let i = 0; i < this.documentos.length; i++) {
              this.checks[`documentacion${i}`].progress = "loading";
              await upload(this.documentos[i]);
              await timer(1700);
              this.checks[`documentacion${i}`].progress = true;
              // this.$root.$emit(
              // 	"snack",
              // 	`Documentacion subida ${i + 1}/${this.documentos.length}`
              // );
            }
          } catch (e) {
            this.$root.$emit("snack", "No se ha podido subir la documentacion");
            this.cargandoContrato = false;
            reject();

            return;
          }

          try {
            await axios({
              method: "POST",
              url: `${process.env.VUE_APP_OUR_API_URL}/contratos.php`,
              data: {
                ...contratoPotencial,
                estado,
                token: this.$store.getters.getJwtEmpresa,
                IdAgente: this.idAgente,
              },
            });
          } catch (e) {
            this.$root.$emit(
              "snack",
              "No se ha podido cambiar el estado del borrador"
            );
            this.cargandoContrato = false;
            reject();

            return;
          }
          resolve();
          this.$root.$emit("snack", "Contrato subido con éxito");
          this.cargandoContrato = false;
          this.hasChanged = false;
          this.$router.push({ name: "Contratos" });
        } catch (err) {
          console.error(err);
          if (err && Array.isArray(err)) {
            this.incidencias = err;
          }
          this.cargandoContrato = false;
          reject();
        }
      });
    },
    cargarDocumento(contratoColgado, name) {
      return new Promise(async (resolve) => {
        let str = this.$store.getters.getDatosEmpresa;
        const { IdUsuario } = str.iss;
        const IdEmpresa = this.$store.getters.getIdEmpresa;

        const fileToB64 = (file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });
        };

        let { data } = await axios({
          method: "GET",
          url: `${process.env.VUE_APP_OUR_API_URL}/subir_documentacion.php`,
          responseType: "blob",
          params: {
            idBorrador: this.$route.query.id,
            token: this.$store.getters.getJwtEmpresa,
            filename: name,
          },
        });
        let extension = name
          .split(".")
          [name.split(".").length - 1].toLowerCase();

        let file = new File(
          [data],
          `borrador_${new Date().getTime()}.${extension}`,
          {
            type: extensions[extension],
          }
        );

        let encoded = await fileToB64(file);

        let aux = await axios({
          method: "POST",
          url: `${process.env.VUE_APP_API_URL}/Documento/ImportDocumento`,
          data: {
            CodigoContrato: Math.abs(contratoColgado.CodigoContrato),
            Documento: encoded.split(",")[1],
            IdEmpresa,
            IdUsuario,
            NombreDocumento: name,
          },
          headers: {
            Authorization: this.$store.getters.getToken,
          },
        });
        resolve();
      });
    },
    async crearProductoFlexible() {
      return new Promise((resolve, reject) => {
        if (
          !confirm(`¿Estás seguro de que quieres crear un producto flexible con estos datos?:\n
				FEE ENERGIA: ${this.contrato.productoFlexible.feeEnergia}\n
				FEE P1: ${this.contrato.productoFlexible.p1}\n
				FEE P2: ${this.contrato.productoFlexible.p2}\n
				FEE P3: ${this.contrato.productoFlexible.p3}\n
				FEE P4: ${this.contrato.productoFlexible.p4}\n
				FEE P5: ${this.contrato.productoFlexible.p5}\n
				FEE P6: ${this.contrato.productoFlexible.p6}
				`)
        ) {
          reject("CREACION PRODUCTO CANCELADA");
          return;
        }
        if (!this.contrato.detallesContratacion.tarifa) {
          reject("Falta tarifa");
          return;
        }
        const nombre = `FLEXIBLE_${this.$store.getters.getNombreEmpresa.toUpperCase()}_${new Date().getTime()}`;
        try {
          axios({
            method: "POST",
            url: `${process.env.VUE_APP_OUR_API_URL}/altaproducto.php`,
            data: {
              token: this.$store.getters.getJwtEmpresa,
              nombre,
              entorno: this.$store.getters.getEntorno,
              feeenergia: this.contrato.productoFlexible.feeEnergia,
              feep1: this.contrato.productoFlexible.p1 || 0,
              feep2: this.contrato.productoFlexible.p2 || 0,
              feep3: this.contrato.productoFlexible.p3 || 0,
              feep4: this.contrato.productoFlexible.p4 || 0,
              feep5: this.contrato.productoFlexible.p5 || 0,
              feep6: this.contrato.productoFlexible.p6 || 0,
              tarifa: this.contrato.detallesContratacion.tarifa.TextoTarifa.replaceAll(
                / ML/g,
                ""
              ),
            },
          })
            .then((res) => {
              this.$root.$emit(
                "snack",
                "El producto flexible se ha creado con éxito"
              );
              resolve(nombre);
            })
            .catch((err) => {
              this.$root.$emit(
                "snack",
                "El producto flexible no se ha podio crear"
              );
              reject();
            });
        } catch (e) {
          console.error(e);
          reject();
        }
      });
    },
    flexibleDetalles() {
      return new Promise(async (resolve) => {
        this.detallesFlexible = {
          show: true,
        };
        resolve();
      });
    },
    async subirDocumento() {
      if (!this.$route.query.id) return;
      try {
        await this.changeFileNameF(this.newFile.name);
        this.changeFileName.show = false;
      } catch (e) {
        this.changeFileName.show = false;
        this.sendingFile = false;
        this.newFile = null;
        return;
      }
      this.newFile = new File([this.newFile], this.changeFileName.newName, {
        type: this.newFile.type,
      });

      let formData = new FormData();
      formData.append("idBorrador", this.$route.query.id);
      formData.append(
        "documentoName",
        this.changeFileName.newName + this.changeFileName.extension
      );
      formData.append("token", this.$store.getters.getJwtEmpresa);
      formData.append("documento", this.newFile);

      try {
        this.sendingFile = true;
        await axios({
          method: "POST",
          url: `${process.env.VUE_APP_OUR_API_URL}/subir_documentacion.php`,
          data: formData,
        });
        this.newFile = null;
        this.sendingFile = false;
        this.getDocumentacion();
      } catch (e) {
        if (e.response.status == 413) {
          this.$root.$emit(
            "snack",
            "El documento es demasiado grande para ser subido"
          );
          this.sendingFile = false;
          this.newFile = null;
          return;
        }
        this.$root.$emit("snack", "No se ha podido subir el archivo");
        this.sendingFile = false;
        this.newFile = null;
        return;
      }
    },
    changeFileNameF(name) {
      return new Promise((resolve, reject) => {
        this.changeFileName.show = true;
        this.changeFileName.newName = name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.changeFileName.extension =
          "." + name.split(".")[name.split(".").length - 1];
        this.changeFileName.resolve = resolve;
        this.changeFileName.reject = reject;
      });
    },
    verDocumento(name, download) {
      if (!this.$route.query.id) return;
      this.downloadingFile = true;
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_OUR_API_URL}/subir_documentacion.php`,
        responseType: "blob",
        params: {
          idBorrador: this.$route.query.id,
          token: this.$store.getters.getJwtEmpresa,
          filename: name,
        },
      }).then((res) => {
        let extension = name
          .split(".")
          [name.split(".").length - 1].toLowerCase();

        let file = new File([res.data], name, {
          type: extensions[extension],
        });
        downloadFile(file, !download);
        this.downloadingFile = false;
        // window.open(fileURL);
      });
    },
    borrarDocumento(name) {
      if (
        !this.$route.query.id ||
        !confirm("¿Estás seguro de que quieres eliminar este documento?")
      )
        return;
      axios({
        method: "DELETE",
        url: `${process.env.VUE_APP_OUR_API_URL}/subir_documentacion.php`,
        params: {
          idBorrador: this.$route.query.id,
          token: this.$store.getters.getJwtEmpresa,
          filename: name,
        },
      })
        .then((res) => {
          this.getDocumentacion();
        })
        .catch((err) => {
          console.error(err);
          this.$root.$emit("snack", "No se ha podido eliminar el archivo");
        });
    },
    humanFileSize,
    parseDate,
  },
  mounted() {
    let str = this.$store.getters.getDatosEmpresa;
    // console.log(str)

    const { IdUsuario, IdAgente } = str.iss;
    const IdEmpresa = this.$store.getters.getIdEmpresa;

    this.idAgente = IdAgente;
    this.idUsuario = IdUsuario;
    this.idEmpresa = IdEmpresa;

    for (let i = 0.25; i <= 100; i += 0.25) {
      this.selects.flexible.feeEnergia.push(i);
    }
    for (let i = 0; i <= 100; i += 0.5) {
      this.selects.flexible.incrementoPotencia.push(i);
    }

    this.checks.cargando = { progress: "loading", texto: "Cargando datos" };
    if (!!this.$route.query.id) {
      this.checks.ciudades = {
        progress: false,
        texto: "Cargando ciudades",
      };
      this.checks.calles = {
        progress: false,
        texto: "Cargando calles",
      };
      this.checks.borrador = {
        progress: false,
        texto: "Cargando datos del borrador",
      };
    }
    this.cargandoContrato = true;

    Promise.all([
      this.getGrupoProductoServicios(),
      this.getTarifasContratacion(),
      this.getPerfilFacturacionContratacion(),
      this.getTipoCobroBancarios(),
      this.getCnaeBancarios(),
      this.getPeriodoLecturaOtros(),
      this.getTipoSolicitudOtros(),
      this.getModeloContratoOtros(),
      this.getIdiomasContratoOtros(),
      this.getFechaEfectoOtros(),
      this.getColectivoRepresentante(),
      this.getDocumentacion(),
      // this.getCapacidad();
    ])
      .then(async () => {
        this.checks.cargando.progress = true;
        if (!!this.$route.query.id) {
          let data = await this.getDatosBorrador();
          if (this.contrato.detallesContratacion.tarifa != null) {
            this.flexibleName = data.flexible;
            await this.getProductoContratacion(
              this.contrato.detallesContratacion.tarifa
            );
            await this.getDatosFlexible();
          }
        }
        this.loadingData = false;
        this.cargandoContrato = false;
      })
      .catch((e) => {
        this.checks.cargando.progress = "error";
        this.$root.$emit("snack", "Ha ocurrido un error al cargar los datos");
        this.$router.go(-1);
        return;
      });
  },
  watch: {
    "busqueda.ciudad.puntoSuministro"(val) {
      if (val === "" || val === null || val.length <= 2) {
        this.ciudadesPosibles.puntoSuministro = [];
        this.ciudadFound = false;
        return;
      }
      if (this.ciudadFound) return;
      this.buscarCiudad(val);
      if (this.isLoading == true) return;
      this.isLoading = true;
    },
    "busqueda.ciudad.datosTitular"(val) {
      if (val === "" || val === null || val.length <= 2) {
        this.ciudadesPosibles.datosTitular = [];
        this.ciudadFound = false;
        return;
      }
      if (this.ciudadFound) return;

      this.buscarCiudad(val, "datosTitular");

      if (this.isLoading == true) return;

      this.isLoading = true;
    },
    "busqueda.ciudad.datosEnvio"(val) {
      if (val === "" || val === null || val.length <= 2) {
        this.ciudadesPosibles.datosEnvio = [];
        this.ciudadFound = false;
        return;
      }
      if (this.ciudadFound) return;

      this.buscarCiudad(val, "datosEnvio");

      if (this.isLoading == true) return;

      this.isLoading = true;
    },
    contrato: {
      handler(val) {
        if (!this.loadingData) {
          this.hasChanged = true;
        }
      },
      deep: true,
    },
    cargandoContrato(val) {
      if (val) this.checksPanel = true;
      else {
        setTimeout(() => {
          this.checksPanel = false;
          this.checks = {};
        }, 1000);
      }
    },
    esBorrador(val) {
      this.cargarContrato(true);
    },
    esEmpresa(val) {
      if (!val) this.contrato.datosTitular.razonSocial = "";
      else {
        this.contrato.datosTitular.nombre = "";
        this.contrato.datosTitular.apellido1 = "";
        this.contrato.datosTitular.apellido2 = "";
      }
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoggedIn && this.hasChanged == true) {
      this.unsavedChanges.show = true;
      try {
        await new Promise((resolve, reject) => {
          this.unsavedChanges.resolve = resolve;
          this.unsavedChanges.reject = reject;
        });

        this.esBorrador = true;
        this.cargarContrato(false).then((res) => {
          next();
        });
        next();
      } catch (e) {
        this.unsavedChanges.show = false;
        if (e) next();
        else next(false);
      }
    } else {
      next();
      return;
    }
  },
};
</script>

<style></style>
